import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { makeStyles } from '@material-ui/core';
import GenericSkeleton from './genericComponents/Skeletons/GenericSkeleton';

export const Button = ({ actionType, action, icon, text, buttonClass }) => {

    return actionType === 'link' ? (
        <Link to={action}>
            <button className={`btn ${buttonClass} d-flex align-center justify-center text-center`}>
                {icon && icon}
                <span>{text}</span>
            </button>
        </Link>
    ) : (
        <button
            onClick={action}
            className={`btn ${buttonClass} d-flex align-center justify-center text-center`}
        >
            {icon && icon}
            <span>{text}</span>
        </button>
    );
};

Button.propTypes = {
    actionType: PropTypes.oneOf(['link', 'onClick']),
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    icon: PropTypes.node,
    text: PropTypes.string.isRequired,
    buttonClass: PropTypes.string,
};

const PageHeader = ({
    heading = '',
    subHeading = '',
    icon = 'ri-cpu-line',
    imgIcon = '/images/load-balancer-icon.svg',
    commonDivMargin = null,
    primaryButton = {
        actionType: 'link',
        action: '',
        icon: null,
        text: 'Primary Button',
        buttonClass: 'btn-primary',
    },
    secondaryButton = null,
    tertiaryButton = null,
}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} d-flex align-center wrapper-page-header space-between ${commonDivMargin ? 'mb-24' : null } `}>
            <div className='d-flex align-center'>
                <div
                    className='sq-avtar-one d-flex align-center justify-center mr-10'
                    style={{
                        width: '56px',
                        height: '56px',
                        borderRadius: '6px',
                        backgroundColor: '#EBF5FF'
                    }}

                >
                    {icon ? <span className={`${icon} font-28 text-anchor-blue`}></span> : <img src={imgIcon} alt=".." />}
                </div>
                <div>
                    <div className='font-18 font-weight-600 color-header-new'>
                        {heading}
                    </div>
                    {subHeading && (
                        <div className='font-12 color-subheader-new font-weight-400'>
                            {subHeading}
                        </div>
                    )}
                </div>
            </div>
            <div className='btn-group'>
                {tertiaryButton && <Button {...tertiaryButton} />}
                {secondaryButton && <Button {...secondaryButton} />}
                {primaryButton && <Button {...primaryButton} />}
            </div>
        </div >
    );
};

const useStyles = makeStyles({
    root:{
        '& .btn-group':{
            '& .btn':{
                marginRight:'5px',
                '&:last-child':{
                    marginRight:'0px'
                }
            }
        }
    }
  });

PageHeader.propTypes = {
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    icon: PropTypes.string,
    imgIcon: PropTypes.string,
    primaryButton: PropTypes.shape({
        actionType: PropTypes.oneOf(['link', 'onClick']),
        action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        icon: PropTypes.node,
        text: PropTypes.string,
        buttonClass: PropTypes.string,
    }),
    secondaryButton: PropTypes.shape({
        actionType: PropTypes.oneOf(['link', 'onClick']),
        action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        icon: PropTypes.node,
        text: PropTypes.string,
        buttonClass: PropTypes.string,
    }),
    tertiaryButton: PropTypes.shape({
        actionType: PropTypes.oneOf(['link', 'onClick']),
        action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        icon: PropTypes.node,
        text: PropTypes.string,
        buttonClass: PropTypes.string,
    }),
};

export default PageHeader;


export const PageHeaderSkeleton = () => {
    const classes = useStyles();
    return (
        <div className={`${classes.root} d-flex align-center wrapper-page-header space-between 'mb-24' } `} style={{width:'100%'}}>
            <div className='d-flex align-center'>
                <div
                    className='sq-avtar-one d-flex align-center justify-center mr-10'
                    style={{
                        width: '56px',
                        height: '56px',
                        borderRadius: '6px',
                        backgroundColor: '#EBF5FF'
                    }}

                >
                    <GenericSkeleton variant={'rect'} width={'100%'} height={'100%'} style={{borderRadius:'6px'}} />
                </div>
                <div>
                    <div className='font-18 font-weight-600 color-header-new'>
                        <GenericSkeleton variant={'text'} width={'330px'} height={'27px'} />
                    </div>
                    <GenericSkeleton variant={'text'} width={'350px'} height={'18px'} />
                </div>
            </div>
            <div className='btn-group'>
                <GenericSkeleton variant={'rect'} width={125} height={40} style={{borderRadius:'6px'}}/>
            </div>
        </div >
    )
}
