

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SquareAvatar from '../../../../components/genericComponents/AvatarSquare';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { styled } from '@material-ui/core/styles';
import Header from './Header';
import Grid from '@material-ui/core/Grid';
import GeneralData from '../component/GeneralData';
import ClickableStepper from '../../../../components/wizardComponents/ClickableStepper';
import properties from '../../../../properties/properties';
import GenerateURL, { GenerateSearchURL } from '../../../../util/APIUrlProvider';
import InvokeApi from '../../../../util/apiInvoker';
import StageProgressDetail from './StageProgressDetail';
import { Link } from 'react-router-dom';
import FilterListIcon from '@material-ui/icons/FilterList';
import CardFooter from './CardFooter';
import ReplayIcon from '@material-ui/icons/Replay';
import StopIcon from '@material-ui/icons/Stop';
import { Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from '../../../../components/Pagination';
import RetryPopup from './RetryPopup';
import { Loading, LoadingContainer } from '../../../utils/Loading';
const ExecutionHistory = styled('div')({
  paddingTop: '8px',
  margin: '32px'
})

const MainHeader = styled('div')({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '60px 300px 310px 1fr'
  ,
  '& .service-name-chip-div': {
    lineHeight: 0
  },
  '& .service-name': {
    margin: '0px'
  },
  '& .service-details .owner-name .owner': {
    marginLeft: '0px'
  }
})

const CardParentDiv = styled('div')({
  backgroundColor: "#fff",
  border: '1px solid #dedede',
  borderRadius: '16px',
  boxShadow: 'rgba(0, 0, 0, 0.06) 0.877px 1.798px 21px 0px',
  marginBottom: '2rem',
  marginTop: '2rem',
  marginLeft: '32px',
  marginRight: '32px',
  '& .md-stepper-horizontal': {
    backgroundColor: '#fff'
  }
})


const PipelineStageExectuion = styled('div')({
  backgroundColor: '#fbfbfb',
  display: 'grid',
  padding: '20px',
  gridTemplateColumns: '30% 1fr',
  justifyContent: 'space-between',
  borderTop: '1px solid #eeeeee',
  borderBottom: '1px solid #eeeeee',
  '& .border-blue': {
    border: '2px solid #0086ff!important',
    color: '#0086ff'

  },
  '& .md-stepper-horizontal': {
    backgroundColor: '#fbfbfb'
  },
  '& .md-stepper-horizontal .md-step': {
    width: ''
  },
  '& .button-navigator': {
    display: 'flex',
    justifyContent: 'flex-end',
  }
})

const MainDivFlex = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0px 20px 10px',
  '& .pipeline-service-data': {
    margin: '0px'
  }

})

const FlexBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const useStyles = makeStyles((theme) => ({
  '.MuiPaper-root': {
    backgroundColor: '#0f7f7!important'
  },
  appBar: {
    position: 'relative',
  },
  title: {
    flex: 1,
    color: '#fff!important'
  },
  timeSection: {
    padding: '4px 10px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '16px',
    fontSize: '12px',
    width: 'fit-content',
    position: 'relative',
    margin: 'auto',
    zIndex: 99
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#ccc',
    top: '1.5rem',
    position: 'absolute',
    zIndex: 9
  },
  mainsec: {
    position: 'relative',
    margin: '32px 0px'
  },
  margin: {
    width: '32px',
    border: '2px solid rgb(0, 134, 255)',
    height: '32px',
    display: 'inline-block',
    borderRadius: '50%',
    '&.btn-filter': {
      marginRight: 5,
      marginLeft: 5
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function PipelineExecutionHistory({reTriggerError,resetTriggerError,...props}) {
  const classes = useStyles();
  const [state, setState] = React.useState({ curr_page: 1, total_page: 1, });
  const pipeline = props.pipeline;
  const application_id = props.application_id;
  const pipeline_instance=props.pipeline_instance
  const pipeline_current_page = props.pipeline_current_page;
  const current_page_no = props.current_page_no;

  console.log(pipeline,pipeline_current_page,"historydata")

  useEffect(() => {
    if (state.open) {
      requestPipelineAllExeccutions();
    }
  }, [pipeline.id]);

  function requestPipelineAllExeccutions() {
    var requestInfo = {
      endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }
    setState(new_state => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(requestInfo, handleResponse, handleError);
  }

  console.log("pipeline data from props ========>", pipeline)

  function handleResponse(response) {
    updateResponseForRunComponents(response.results)
    setState(new_state => ({
      ...new_state,
      data: response.results,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous ? properties.api.baseURL + response.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: 1,
    }));
  }

  function handleError(error) {
    setState(new_state => ({
      ...new_state,
      loaded: true,
      error: error
    }));
  }

  function updateResponseForRunComponents(pipelines) {
    // if (pipelines) {
    //   pipelines.forEach(pipeline => {
    //     if (pipeline.instance) {
    //       pipeline.instance.forEach(pipeline_instance => {
    //         updateRunComponetsForPipelineHistory(pipeline.components, pipeline_instance.components)
    //       })
    //     }
    //   });
    // }
    if (pipelines) {
      pipelines.forEach((pipeline_instance) => {
        updateRunComponetsForPipelineHistory(pipeline.components, pipeline_instance.components)
      })
    }
  }
  function updateRunComponetsForPipelineHistory(all_components, run_components) {
    var components_map = {}
    if (all_components) {
      run_components.forEach(component => {
        components_map[component.name] = component
      })

      if (run_components) {
        all_components.forEach(component => {
          if (components_map[component.name]) {
            components_map[component.name].part_of_execution = true
          } else {
            component.part_of_execution = false
            run_components.push(component)
          }

        })
      }
    }
  }

  const handleClickOpen = () => {
    setState({
      ...state,
      open: true
    });
    requestPipelineAllExeccutions();
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false
    });
  };

  console.log(state.loaded, "loaded ============>")


  //prev function 

  function fetchPrevExecutionHistoryInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(
      requestInfo,
      fetchPrevExecutionHistoryInfoSuccess,
      fetchPrevExecutionHistoryInfoFailure
    );
  }

  function fetchPrevExecutionHistoryInfoSuccess(response) {
    setState(new_state => ({
      ...new_state,
      data: response.results,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous ? properties.api.baseURL + response.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page - 1),
    }));
  }

  function fetchPrevExecutionHistoryInfoFailure(error) {
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      error: error
    }));
  }

  //next function

  function fetchNextExecutionHistoryInfo(data, url) {
    var requestInfo = {
      endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    if (data) {
      requestInfo.endPoint = GenerateSearchURL(data, requestInfo.endPoint);
    }

    if (url) {
      requestInfo.endPoint = url;
    }
    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(
      requestInfo,
      fetchNextExecutionHistoryInfoSuccess,
      fetchNextExecutionHistoryInfoFailure
    );
  }

  function fetchNextExecutionHistoryInfoSuccess(response) {
    setState(new_state => ({
      ...new_state,
      data: response.results,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous ? properties.api.baseURL + response.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(new_state.curr_page + 1),
    }));
  }

  function fetchNextExecutionHistoryInfoFailure(error) {
    console.log("pipe exec get error", error);
    setState((new_state) => ({
      ...new_state,
      loaded: true,
      error: error
    }));
  }

  //page function

  function fetchPageExecutionHistoryInfo(enteredPageNumber) {
    var requestInfo = {
      endPoint: GenerateURL({ pipeline_id: pipeline.id }, properties.api.trigger_pipeline),
      httpMethod: "GET",
      httpHeaders: { "Content-Type": "application/json" }
    }

    if (enteredPageNumber > 1) {
      requestInfo.endPoint =
        requestInfo.endPoint +
        "?limit=10&offset=" +
        (enteredPageNumber - 1) * 10;
    }

    var current_page = enteredPageNumber;

    setState((new_state) => ({
      ...new_state,
      loaded: false,
    }));
    InvokeApi(
      requestInfo,
      fetchPageExecutionHistoryInfoSuccess,
      fetchPageExecutionHistoryInfoFailure
    );
  }

  function fetchPageExecutionHistoryInfoSuccess(response) {
    setState(new_state => ({
      ...new_state,
      data: response.results,
      loaded: true,
      count: response.count,
      next: response.next ? properties.api.baseURL + response.next : null,
      previous: response.previous ? properties.api.baseURL + response.previous : null,
      error_msg: null,
      total_page: Number.isInteger(Number(response.count) / 10)
        ? (Number(response.count) / 10).toFixed(0)
        : (Number(response.count) / 10 + 1).toFixed(0) > Number(response.count) / 10 + 1
          ? (Number(response.count) / 10 + 1).toFixed(0) - 1
          : (Number(response.count) / 10 + 1).toFixed(0),
      curr_page: Number(count),
    }));
  }

  function fetchPageExecutionHistoryInfoFailure(error) {
    console.log("pipe exec get error", error);
    setState((new_state) => ({
      ...new_state,
      loaded: false,
      error: error
    }));
  }


  console.log('lt)pssdsd',state,pipeline_current_page)
  return (
    <div>
      <>
        <Tooltip title="View Execution History">
          <Link to={{
            pathname: "/application/" + application_id + "/pipeline/listing/" + pipeline.id + "/history",
            search: `pipeline_id=${pipeline.id}&application_id=${application_id}&current_page_no=${current_page_no}`,
            // state : {
            //   pipeline : pipeline,
            //   application_id : application_id,
            //   //pipeline_instance : pipeline_instance,
            //   pipeline_current_page: pipeline_current_page,
            //   current_page_no: current_page_no
            // }
          }}
          >
            <IconButton /* onClick={handleClickOpen} */ aria-label="delete" className={classes.margin + " " + "btn-filter"}>
              <FilterListIcon fontSize="11px" style={{ color: '#0086ff' }} />
            </IconButton>
          </Link>
        </Tooltip>
      </>
      <Dialog fullScreen open={state.open} onClose={handleClose} TransitionComponent={Transition} style={{ backgroundColor: '#f0f7f7' }}>
        <AppBar className={classes.appBar}>
          <Toolbar>

            <Typography variant="h6" className={classes.title}>
              Execution History of {pipeline.name}
            </Typography>
            <div style={{ margin: "15px" }}>
              <IconButton edge="end" color="inherit" onClick={requestPipelineAllExeccutions} aria-label="close">
                <RefreshIcon />
              </IconButton>
            </div>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>

          </Toolbar>
        </AppBar>
        <div style={{ padding: "15px 40px 0 15px" }}>
          {/* <Grid container className="search-containter">
            <Grid item lg={8}>
            </Grid>
            <Grid item lg={4} sm={12} xl={3} xs={12}>
              <Pagination
                total_count={state.total_page}
                current_page_count={state.curr_page}
                next={state.next}
                previous={state.previous}
                on_previous_click={() => {
                  fetchPrevExecutionHistoryInfo(null, state.previous);
                }}
                on_next_click={() => {
                  fetchNextExecutionHistoryInfo(null, state.next);
                }}
                on_pageNumber_click={(pageNumber) => {
                  fetchPageExecutionHistoryInfo(pageNumber);
                }}
              />
            </Grid>
          </Grid> */}
          <div>
            <Pagination
              total_count={state.total_page}
              current_page_count={state.curr_page}
              next={state.next}
              previous={state.previous}
              on_previous_click={() => {
                fetchPrevExecutionHistoryInfo(null, state.previous);
              }}
              on_next_click={() => {
                fetchNextExecutionHistoryInfo(null, state.next);
              }}
              on_pageNumber_click={(pageNumber) => {
                fetchPageExecutionHistoryInfo(pageNumber);
              }}
            />
          </div>
        </div>
        <Grid container style={{ backgroundColor: '#f0f7f7' }}>
          <Grid item xs={12}>

            {
              state.loaded ?
                <>
                  <>
                    <>
                      {
                        state?.data?.map(pipeline_instance => (
                          <CardParentDiv>
                            <div style={{ display: 'flex', alignItems: "center", padding: '20px' }}>
                              <Header varient="without_header" application_id={application_id} pipeline_instance={pipeline_instance} pipeline={pipeline} />
                            </div>
                            <PipelineStageExectuion>

                              <GeneralData pipeline={pipeline} pipeline_instance={pipeline_instance} />
                              <StageProgressDetail pipeline={pipeline} pipeline_instance={pipeline_instance} />
                            </PipelineStageExectuion>
                            <MainDivFlex>
                              <CardFooter components={pipeline_instance.components} tags={pipeline.tags} />
                              <div className="d-flex align-center ">
                                <Link style={{ fontSize: '12px', color: 'rgb(0, 134, 255)', }} to={"/application/" + application_id + "/pipeline/" + pipeline_instance.pipeline_id + "/execution/" + pipeline_instance.id}>
                                  <IconButton style={{
                                    width: '32px',
                                    border: '2px solid rgb(0, 134, 255)',
                                    height: '32px',
                                    display: 'inline-block',
                                    borderRadius: '50%'
                                  }} title="View Details" aria-label="delete" className={classes.margin + classes.borderBlue}>
                                    <span className="flaticon-visibility-button"></span>
                                  </IconButton>
                                </Link>

                                
                                <>
                                  <RetryPopup
                                    pipeline={pipeline}
                                    variant="execution_history"
                                    retryPipeline={props.retrigger_pipeline}
                                    pipeline_instance={pipeline_instance}
                                    reTriggerError={reTriggerError}
                                    resetTriggerError={resetTriggerError}
                                    
                                  />
                                 
                                </>
                                <>
                                  {
                                    pipeline_instance.status == "RUNNING" || pipeline_instance.status == "IN_QUEUE" ?
                                      <span className="play-icon" >
                                        <IconButton title="Revoke Pipeline" onClick={() => { props.revoke_pipeline(pipeline_instance.pipeline_id, pipeline_instance.id) }} style={{ color: '#0086ff', marginLeft: 5 }} className={classes.margin + " " + "text-anchor-blue font-12 "}>
                                          <span className="flaticon--rounded-button"><StopIcon className="color-danger" style={{ verticalAlign: 'top', fontSize: 22, marginTop: '-2px' }} /></span>
                                        </IconButton>
                                      </span> : null
                                  }
                                </>
                              </div>
                            </MainDivFlex>

                          </CardParentDiv>

                        ))
                      }
                    </>
                  </>

                </>
                :
                <LoadingContainer />
            }








            {/* <ExecutionHistory>
                  <MainHeader>
                  <Header pipeline={pipeline} /> 
                    
                  </MainHeader>
                </ExecutionHistory>
                {
                  state.loaded ?
                  <>
                    {
                      state.data.map(pipeline_instance=>(
                        <CardParentDiv>
                            <GeneralData pipeline={pipeline} pipeline_instance={pipeline_instance} />
                            <StageProgressDetail pipeline={pipeline} pipeline_instance={pipeline_instance} />
                            <Link style={{fontSize:'12px', color:'rgb(0, 134, 255)'}} to={"/application/"+application_id+"/pipeline/"+pipeline.id+"/execution/"+pipeline_instance.id}>View Execution Details</Link>
                        </CardParentDiv>
                      ))
                    }
                  </>
                  :null
                } */}
          </Grid>
        </Grid>
      </Dialog>
    </div >
  );
}


PipelineExecutionHistory.propTypes = {
  ...PropTypes.objectOf(PropTypes.any),
}