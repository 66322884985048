import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import GenerateURL from '../../../util/APIUrlProvider';
import DeleteConfirmationDialogue from '../../settings/credentials/components/DeleteConfirmationDialogue';
import properties from '../../../properties/properties';
import { DeleteData } from '../../../util/apiInvoker';
import { GetAuth } from '../../../util/security';
import { Tooltip } from '@material-ui/core';
import useFetchPermissions from '../../../components/customHooks/useFetchPermissions';
import RoundAvatar from '../../../components/RoundAvatar/RoundAvatar';
import GenericSkeleton from '../../../components/genericComponents/Skeletons/GenericSkeleton';

const SecretList = (props) => {
    const classes = useStyles();
    const permissions = useFetchPermissions();
    const data = props.data ? props.data : {};
    const refreshFun = props.refreshFun ? props.refreshFun : () => { }
    const getCredentialLogo = (cred_type) => {
        console.log(cred_type, "getCredentialLogo")
        switch (cred_type) {
            case "TOKEN":
                return { "type": cred_type, logo: "ri-copper-coin-line" };
            case "USERNAME_PASSWORD":
                return { "type": cred_type, logo: "ri-lock-password-line" };
            case "ACCESS_TOKEN":
                return { "type": cred_type, logo: "ri-account-circle-line" };
            default:
                return null
        }

    }
    const cred_type = getCredentialLogo(data.credential_type);
    const url = GenerateURL({ credential_id: data.id }, properties.api.credential, true)
    const is_permitted = permissions?.POST?.includes(url);
    return (
        <>
            <div className={classes.secretList}>
                <div className='d-flex align-center'>
                    {
                        cred_type ?
                            <RoundAvatar
                                icon={cred_type && cred_type.logo ? <span className={`${cred_type.logo} font-24 color-blue`}></span> : null} /> :
                            <RoundAvatar
                                imagePath={data.credential_type == "SSH_KEY" ? "/images/creds_icons/ssh-creds-icon.svg" : data.credential_type == "AWS" ? "/images/creds_icons/aws_cred_logo.svg" : null} />
                    }

                    <Tooltip title={data.credential_type ? data.credential_type : "N/A"}>
                        <p className='font-12 text-ellipsis-110 ml-5'>{data.credential_type ? data.credential_type : "N/A"}</p>
                    </Tooltip>
                </div>
                {is_permitted ?
                    <Tooltip title={data.name ? data.name : "N/A"}>
                        <Link to={"/secrets/" + data.id + "/edit"} className="color-anchor-blue font-12 ml-5 text-ellipsis-110">
                            {data.name ? data.name : "N/A"}
                        </Link>
                    </Tooltip>
                    :
                    <Tooltip title={"You don't have permission to perform this action"}>
                        <p className='font-12 ml-5 text-ellipsis-110'>{data.name ? data.name : "N/A"}</p>
                    </Tooltip>
                }
                <Tooltip title={data.username ? data.username : "N/A"}>
                    <p className='font-12 text-ellipsis-110'>{data.username ? data.username : "N/A"}</p>
                </Tooltip>
                <Tooltip title={data.created_at ? moment(data.created_at).fromNow() : "N/A"}>
                    <p className='font-12 text-ellipsis-110'>{data.created_at ? moment(data.created_at).fromNow() : "N/A"}</p>
                </Tooltip>
                <Tooltip title={data.updated_at ? moment(data.updated_at).fromNow() : "N/A"}>
                    <p className='font-12  text-ellipsis-110'>{data.updated_at ? moment(data.updated_at).fromNow() : "N/A"}</p>
                </Tooltip>
                <div className='r-col7 repos-card-content' style={{ marginLeft: 'auto', marginRight: 0 }}>
                    <OptionList
                        data={{ id: data.id }}
                        credential_name={data.name}
                        refreshFun={refreshFun} 
                        is_permitted={is_permitted}
                        />
                </div>
            </div>
        </>
    )
}

SecretList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const ITEM_HEIGHT = 48;
const OptionList = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, setState] = useState({
        post_status: "POST_SUCCESS",
        error: {}
    })
    const open = Boolean(anchorEl);
    const data = props.data ? props.data : {};
    const edit_id = data.id ? data.id : "";
    const apiUrl = props.apiUrl ? props.apiUrl : ""
    const refreshFun = props.refreshFun ? props.refreshFun : () => { };
    const credential_name = props.credential_name ? props.credential_name : "";
    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function onDeleteHandler() {
        setAnchorEl(null);
        setDeleteOpen(true);
    }
    const handleCloseDeleteDialogue = () => {
        refreshFun();
        setDeleteOpen(false);
        setState({ ...state, error: {}, post_status: "POST_SUCCESS" })
    };

    const onDeleteRequest = () => {

        var saveUrl = GenerateURL({ credential_id: edit_id }, properties.api.credential)
        console.log("sas", saveUrl)
        DeleteData(saveUrl, data, handleDeleteSuccess, handleSaveFailure, true)
        setState({ ...state, error: {}, post_status: "POST_REQUESTED" })

    }

    function handleDeleteSuccess(response) {
        setTimeout(() => {
            handleCloseDeleteDialogue();
        }, 400);
    }
    function handleSaveFailure(response) {
        setTimeout(() => {
            handleCloseDeleteDialogue();
        }, 400);
    }

    var url = GenerateURL({credential_id : edit_id}, properties.api.credential, true)
    const is_permitted = props.is_permitted; //useFetchPermissions()?.POST.includes(url);
    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                 <span className='ri-more-2-fill font-20'></span>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem onClick={handleClose} style={{ display: "grid" }}>
                    {
                        is_permitted ?
                            <Link to={"/secrets/" + edit_id + "/edit"} className="d-flex align-center space-between font-12" style={{color:'#505050'}}>
                                <span className="hover-content-link"><BorderColorIcon />&nbsp;Edit</span>
                            </Link>
                            :
                            <Tooltip title="You are not allowed to perform this action">
                                <span className="hover-content-link"><BorderColorIcon />&nbsp;Edit</span>
                            </Tooltip>

                    }
                </MenuItem>
                <MenuItem onClick={handleClose} style={{ display: "grid", justifyContent: 'center' }}>
                    {
                        is_permitted ?
                            <button onClick={onDeleteHandler} className="MuiButtonBase-root MuiIconButton-root" tabIndex="0" type="button"><span className="MuiIconButton-label"><svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ color: 'rgb(255, 137, 105)' }}><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>&nbsp;Delete</span><span className="MuiTouchRipple-root"></span></button>
                            :
                            <Tooltip title="You are not allowed to perform this action">
                                <button className="MuiButtonBase-root MuiIconButton-root" tabIndex="0" type="button"><span className="MuiIconButton-label"><svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" style={{ color: 'rgb(255, 137, 105)' }}><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></svg>&nbsp;Delete</span><span className="MuiTouchRipple-root"></span></button>
                            </Tooltip>
                    }

                    <DeleteConfirmationDialogue onDeleteRequest={onDeleteRequest} open={deleteOpen} handleClose={handleCloseDeleteDialogue}
                        name={credential_name} type="credential" />
                </MenuItem>

            </Menu>
        </>
    )
}

OptionList.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
};

const useStyles = makeStyles({
    secretList: {
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
        padding: '10px',
        gap: '10px',
        borderBottom: '1px solid #e6e6e6',
        '&:last-child': {
            borderBottom: 'none'
        }
    }
});

export default SecretList;

export const SecretListSkeleton = () => {
    const classes = useStyles();
    return (
        <div className={classes.secretList}>
            <div className='d-flex align-center'>
                <GenericSkeleton variant={'circle'} width={48} height={48}/>
                <GenericSkeleton variant={'text'} width={100} height={18}/>
            </div>
           {Array.from({length:4}).map(x => <GenericSkeleton variant={'text'} width={100} height={18}/>)}
            
            <div className='r-col7 repos-card-content' style={{ marginLeft: 'auto', marginRight: 0 }}>
                <GenericSkeleton variant={'rect'} height={20} width={10} />
            </div>
    </div>
    )
} 