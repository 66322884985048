import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Button } from './PageHeader';

const BlankPage = ({ pageIcon, text, primaryButton, additionalComponent=null }) => {
   
    return (
        <div className="main-container-error" style={{ height: 'calc(100vh - 110px)' }}>
            <div
                className='sq-avtar-one d-flex align-center justify-center mb-24 m-auto'
                style={{
                    width: '56px',
                    height: '56px',
                    borderRadius: '6px',
                    backgroundColor: '#EBF5FF'
                }}
            >
               { pageIcon ? pageIcon : null} 
            </div>
            <p className='font-18 font-weight-600 color-primary mb-24'>{text}</p>
            {
                additionalComponent ? 
                additionalComponent :
                <Button {...primaryButton} />
            }
            
        </div>
    )
}


export default BlankPage