import React, { useState, useEffect } from 'react';
import { Input } from '../../../../components/genericComponents/Input';
import { GenericValidator, VALIDATION_TYPE_REQUIRED, VALIDATION_TYPE_SMALLCAP_NUM_HIGHFEN_ONLY, ValidateDataSet } from '../../../../util/Validator';
import GenerateURL from '../../../../util/APIUrlProvider';
import properties from '../../../../properties/properties';
import InvokeApi, { PostData } from '../../../../util/apiInvoker';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { GetAuth } from '../../../../util/security';
import { Loading, LoadingText } from '../../../utils/Loading';
import CurrentProgressData from './CurrentProgressData';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, IconButton, Tooltip, makeStyles, withStyles } from '@material-ui/core';
import PlayCircleOutlineOutlinedIcon from '@material-ui/icons/PlayCircleOutlineOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import '../../../../assets/buttons.css'
import { evaluateAgentStatus } from '../../../../util/util';
import BpAgentStatusActionAlert from '../../../../components/genericComponents/BpAgentStatusActionAlert';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import BuildFlowActionAlert from '../../../../components/genericComponents/BuildFlowActionAlert';
import { error } from 'highcharts';
import CurrentProgressDataNew from './CurrentProgressDataNew';
import Grid from '@material-ui/core/Grid';
import { SdCard } from '@material-ui/icons';
import useFetchPermissions from '../../../../components/customHooks/useFetchPermissions';
import GenericSkeleton from '../../../../components/genericComponents/Skeletons/GenericSkeleton';


const TriggerActionCardNew = (props) => {
    const type = 'BUILD';
    const service_id = props.service_id;
    const env_id = props.env_id;
    const service_data = props.service_data;
    const top_summary_data = props.top_summary_data;
    const is_env_down = props.is_env_down ? props.is_env_down : null
    const deploy_env_offline_flag = props.deploy_env_offline_flag ? props.deploy_env_offline_flag : null;
    const disable_parallel_build = props.disable_parallel_build ? props.disable_parallel_build : null
    const complete_ci_data = props.complete_ci_data
    const env = service_data.env;
    const configuration_data = props.configuration_data;
    const ci_data = props.ci_data ? props.ci_data : null;
    const service_env_name = props.service_env_name;
    const serviceEnv = props.service_env;
    const multiple_build_enabled = props.multiple_build_enabled;
    const env_list = [];
    const all_available_deployments = props.all_available_deployments && props.all_available_deployments.length > 0 ?
        props.all_available_deployments.map(item => {
            return { label: item.deployment_name ? item.deployment_name : item.label, id: item.deployment_name ? item.deployment_name : item.label }
        }) : []
    var url = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url, true);
    const is_permited = useFetchPermissions()?.POST.includes(url);

    const [state, setState] = useState({
        tag: "",
        docker_cache: true,
        data: {

        },
        child: {},
        branch_list: [],
        tag_loaded_status: false,
        errors: {},
        refresh_count: 1,
        open: false,
        branch_loading: false,
        show_bp_agent_warning: false,
        down_bp_agent_names: null,
        type_of_action: 'only-build',
        open_custom: false,
        trigger: null,
        parallBuildFeatureData: null,
        branchRequired: false,
        alert_loading: false,
    });
    var flag_for_feature_tag = properties.flag_for_feature_id
    const build_action_card = props.build_action_card;
    const handleCloseDialog = props.handleCloseDialog;
    // Logic to form env->subenv list
    if (env) {
        env.forEach(element => {
            if (element.id != env_id) {
                env_list.push({
                    id: element.id,
                    label: element.env_master.name + "->" + element.env_name
                });
            }
        });
    }


    useEffect(() => {
        var current_ci_data = ci_data?.find(item => item.status === "APPROVED")
        if (current_ci_data && current_ci_data.git_repo) {
            if (current_ci_data.git_repo.git_url) {
                testAndGetBranches(current_ci_data.git_repo.git_url, current_ci_data.git_repo.credential_id)
            }
        }
        fetchSystemSettingsData()
    }, [])

    useEffect(() => {
        if (multiple_build_enabled) {
            getChildBuildList()
        }
    }, [complete_ci_data])

    function getChildBuildList() {
        var list = []
        complete_ci_data.forEach((item) => {
            if (item.type_label !== null) {
                list.push(item)
            }
        })

        setState((new_state) => ({
            ...new_state,
            child_build_list: list
        }))
    }


    function fetchSystemSettingsData() {
        var requestInfo = {
            endPoint: GenerateURL({}, properties.api.system_settings),
            httpMethod: "GET",
            httpHeaders: { "Content-Type": "application/json" }
        }
        InvokeApi(requestInfo, fetchSystemSettingsDataSuccess, fetchSystemSettingsDataFailed);

        setState(new_state => ({
            ...new_state,
            data_loading: "LOADING"
        }))

    }

    function fetchSystemSettingsDataSuccess(data) {
        var branchRequired = data.find(item => item.key == "BRANCH_SELECTION_REQUIRED_FOR_EVERY_BUILD");
        var disable_parallel_build = data.find(item => item.key === "DISABLE_PARALLEL_BUILD")
        setState(new_state => ({
            ...new_state,
            data_loading: "SUCCESS",
            branchRequired: branchRequired?.value == 'true' ? true : false,
            disable_parallel_build: disable_parallel_build,
        }));

    }
    function fetchSystemSettingsDataFailed(error) {

        setState(new_state => ({
            ...new_state,
            error: error,
            data_loading: "FAILED"
        }))
    }

    function getCommitSha() {
        let parent_ci_info = ci_data.find((item) => {
            if (item.type_label == null) {
                return item
            }
        })
        let default_branch_name = parent_ci_info?.branch_name
        let commit_sha_obj;
        if (state.branch_name) {
            let selected_branch_name = state.branch_name
            commit_sha_obj = state.branch_with_commit_sha && state.branch_with_commit_sha.find((item) => {
                if (item[selected_branch_name]) {
                    return item[selected_branch_name]
                }
            })
        } else {
            commit_sha_obj = state.branch_with_commit_sha && state.branch_with_commit_sha.find((item) => {
                if (item[default_branch_name]) {
                    return item[default_branch_name]
                }
            })
        }
        let commit_sha = commit_sha_obj && Object.values(commit_sha_obj)[0]

        return commit_sha;
    }

    function testAndGetBranches(git_url, credential_id) {
        const post_data = {
            git_url: git_url,
            credential_id: credential_id,
        };
        if (!post_data.credential_id) {
            delete post_data.credential_id;
        }
        if (props.variant == "ServiceCard") {
            PostData(GenerateURL({}, properties.api.git_test), post_data, onFetchSuccess, onFetchFail, true);
        } else {
            PostData(GenerateURL({}, properties.api.git_test), post_data, onFetchSuccess, onFetchFail, false, true);
        }
        setState(new_state => ({
            ...new_state,
            branch_loading: true,
        }));

    }

    function onFetchSuccess(data) {
        if (data.branches && data.branches.length > 0) {
            var branch_list = data.branches.map(item => {
                return { id: item, label: item }
            })
        }
        setState(new_state => ({
            ...new_state,
            branch_loading: false,
            branch_list: branch_list && branch_list.length > 0 ? branch_list : [],
            action_card: !new_state.action_card,
            branch_with_commit_sha: data.branch_with_commit_sha,
        }));

    }
    function onFetchFail(error) {
        setState(new_state => ({
            ...new_state,
            branch_loading: false,
            errors: {
                ...new_state.errors,
                branch_name: error
            }

        }));
    }

    function getEnvNamefromId() {
        var env_data = {};
        if (service_data && service_data.component_env) {
            service_data.component_env.map(item => {
                if (item.id == env_id) {
                    env_data.env_name = item.project_env.name
                    env_data.env_type = service_data.type
                }
            })
        }
        return env_data;
    }

    function successBuildTriggerCallBack(response) {
        setState(prevState => ({
            ...prevState,
            build_status: response.status,
            build_id: response.id,
            trigger: "success",
            tag: "",
            custom_tag: "",
            feature_tag: "",
            branch_name: "",
            docker_cache: true,
            is_deploy: false,
            error: {},
            show_bp_agent_warning: false,
            alert_loading: false,
        }));
        refreshGrid();
    }

    function failedBuildTriggerCallBack(error) {

        setState(prevState => ({
            ...prevState,
            trigger: "failed",
            error_msg: error.detail ? error.detail : error,
            show_bp_agent_warning: false,
            alert_loading: false,
        }));
    }

    function handleClose() {
        setState({
            ...state,
            trigger: null
        });
    }

    function checkForParallelBuild(serviceId, envId) {
        return new Promise(function (myResolve, myReject) {

            var requestInfo = {
                endPoint: GenerateURL({ service_id: serviceId, env_id: envId }, properties.api.buildPossible),
                httpMethod: "GET",
                httpHeaders: { "Content-Type": "application/json" }
            }

            InvokeApi(requestInfo, (fetchedData) => {
                myResolve(fetchedData)
            }, (error) => { myReject(error) })
        });

    }
    function successBuildTriggerCallBackFromActionDialouge(response) {
        setState(prevState => ({
            ...prevState,
            build_status: response.status,
            build_id: response.id,
            trigger: "success",
            tag: "",
            custom_tag: "",
            feature_tag: "",
            branch_name: "",
            docker_cache: true,
            is_deploy: false,
            error: {},
            show_bp_agent_warning: false,
            parallBuildFeatureData: null
        }));
        refreshGrid();
    }

    function failedBuildTriggerCallBackFromActionDialouge(error) {

        setState(prevState => ({
            ...prevState,
            trigger: "failed",
            error_msg: error.detail ? error.detail : error,
            show_bp_agent_warning: false,
            parallBuildFeatureData: null
        }));
    }
    const triggerPipelineFromActionDialouge = (actionType) => {
        if (state.parallBuildFeatureData && actionType != undefined)
            PostData(state.parallBuildFeatureData?.url, { ...state.parallBuildFeatureData?.data, action: actionType }, successBuildTriggerCallBackFromActionDialouge, failedBuildTriggerCallBackFromActionDialouge);
    }

    function validateAndTrigger(deploy_this_tag) {
        console.log(deploy_this_tag, "bfgjfghjbvdhfbvhj")
        var result = { valid: true }
        if (state.build_and_deploy) {
            result = ValidateDataSet(
                {
                    deployment_name: state.deployment_name,
                },
                {
                    deployment_name: [VALIDATION_TYPE_REQUIRED]
                })
        }

        if (state.branchRequired) {
            const resultForBuild = ValidateDataSet(
                {
                    branch_name: state.branch_name,
                },
                {
                    branch_name: [VALIDATION_TYPE_REQUIRED]
                }
            )
            if (!resultForBuild.valid) {
                const branchError = resultForBuild.error
                if (!result.valid) {
                    result.error = { ...result.error, ...branchError }
                }
                else {
                    result.error = branchError
                    result.valid = false
                }
            }
            console.log(resultForBuild, 'rbgh')

        }
        if (result && result.valid) {
            var data = {
                // custom_tag: state.tag,
                branch_name: state.branch_name,
                no_cache: state.docker_cache ? false : true,
                feature_tag: state.feature_tag,
                is_deploy: deploy_this_tag === "DEPLOY_THIS_TAG" ? true : false,
                deployment_name: [],
                commit_sha: getCommitSha()
            }
            if (multiple_build_enabled && state.child_build_override) {
                const child = Object.entries(state.child).map(([key, value]) => ({ [key]: value }));
                data = { ...data, child }
                console.log("jshdf", data);
            }
            if (deploy_this_tag === "DEPLOY_THIS_TAG") {
                data.deployment_name = state.deployment_name
            }
            // else {

            //     delete data.deployment_name

            // }
            if (state.open_custom && state.custom_tag != "") {
                data.custom_tag = state.custom_tag;
                delete data.tag
            } else {
                data.tag = state.tag
                delete data.custom_tag
            }
            if (state.open_custom && (state.tag && state.tag != "")) {
                delete data.tag
            }
            if (!state.open_custom && (state.custom_tag && state.custom_tag != "")) {
                delete data.custom_tag
            }
            if (!data.custom_tag) {
                delete data.custom_tag;
            }
            if (!data.tag) {
                delete data.tag;
            }
            if (!data.branch_name) {
                delete data.branch_name;
            }
            if (!data.feature_tag) {
                delete data.feature_tag
            }
            if (deploy_this_tag === "DEPLOY_THIS_TAG" && all_available_deployments && all_available_deployments.length == 1) {
                data.deployment_name = all_available_deployments[0].label ? [all_available_deployments[0].label] : null
            }
            if (disable_parallel_build && disable_parallel_build.value && disable_parallel_build.value === "true") {
                checkForParallelBuild(service_id, env_id).then(parallelBuildData => {

                    console.log(data, 'd_fgbn')
                    if (!parallelBuildData?.parallel_build_status) {
                        const possibleActions = parallelBuildData?.possible_action
                        setState(prevState => ({
                            ...prevState,
                            parallBuildFeatureData: {
                                parallelBuildData: parallelBuildData,
                                possibleActions: possibleActions,
                                data: data,
                                url: GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url)
                            }
                        }))
                    }
                    else {
                        var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url);
                        setState((prevState) => ({
                            ...prevState,
                            alert_loading: true
                        }))
                        PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack, false, true);
                    }

                }).catch(error => {

                    console.log(error, 'error->>')
                })
            } else {
                console.log("jjsdbhjkdjfbf", data);
                var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url);
                setState((prevState) => ({
                    ...prevState,
                    alert_loading: true
                }))
                PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack, false, true);
            }



            // var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.env_build_request_url);

            // var endPoint = GenerateURL({ service_id: service_id, env_id: env_id }, properties.api.buildPossible);

            // PostData(endPoint, data, successBuildTriggerCallBack, failedBuildTriggerCallBack);

        } else {
            console.log(result, 'rsgt_pps')
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    ...result.error
                }
            });
        }

    }

    const onChangeHandler = (e) => {
        console.log(e.target.name, e.target.value, "shbdhbdshbchsdbc")
        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            console.log(e.target.value, "hjdbhsbcjhsdbchsd")
            var name = e.target.name
            var value = e.target.value
            setState((new_state) => ({
                ...new_state,
                [name]: value,
                errors: {
                    ...new_state.errors,
                    [name]: "",
                }
            }));
        }
    }

    const onTagChangeHandler = (e) => {
        console.log(e.target.name, e.target.value, "shbdhbdshbchsdbc")
        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            console.log(e.target.value, "hjdbhsbcjhsdbchsd")
            var name = e.target.name
            var value = e.target.value
            setState((new_state) => ({
                ...new_state,
                [name]: value,
                errors: {
                    ...new_state.errors,
                    [name]: "",
                }
            }));
        }
    }

    const onChildChangeHandler = (e, type) => {
        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            console.log(e.target.name, e.target.value, type, "dtatnenkjnfjkwens")
            const value = e.target.value
            const name = e.target.name
            setState((new_state) => ({
                ...new_state,
                child: {
                    ...new_state.child,
                    [type]: {
                        ...new_state.child[type],
                        [name]: value,
                    }
                },
                errors: {
                    ...state.errors,
                    [name]: "",
                }
            }));
        }
    }


    const onChildCacheChangeHandler = (e, type) => {
        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            console.log(e.target.name, e.target.checked, type, "dtatnenkjnfjkwens")
            const value = e.target.checked;
            const name = e.target.name;
            console.log("ksjdh", state);
            setState((new_state) => ({
                ...new_state,
                child: {
                    ...new_state.child,
                    [type]: {
                        ...new_state.child?.[type],
                        [name]: !value,
                    }
                },
                errors: {
                    ...state.errors,
                    [name]: "",
                }
            }));
        }
        // if(!(e.target.name == "custom_tag" && e.target.value.includes(" "))){
        //     setState({
        //         ...state,
        //         [e.target.name]: e.target.value,
        //         errors: {
        //             ...state.errors,
        //             [e.target.name]: "",
        //         }
        //     });
        // }
    }
    const onChangeHandlerForDockerCache = (e) => {
        if (!(e.target.name == "tag" && e.target.value.includes(" "))) {
            setState({
                ...state,
                [e.target.name]: e.target.checked,
                errors: {
                    ...state.errors,
                    [e.target.name]: "",
                }
            });
        }
    }

    function checkForFeatureTag() {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem("feature_tag", flag_for_feature_tag);
            var feature_tag = localStorage.getItem("feature_tag");
            feature_tag = JSON.parse(feature_tag) === true;
            if (feature_tag) {
                return (
                    <div className={type == "DEPLOY" ? "deploy_class" : ""}>
                        <Input
                            type="text-new"
                            mandatorySign={type == "BUILD" ? false : true}
                            data={{ feature_tag: state.feature_tag }}
                            name="feature_tag"
                            error={{ feature_tag: state.errors.feature_tag }}
                            onChangeHandler={onChangeHandler}
                            label="Feature Id"
                            placeholder="Enter Feature Id"
                        />
                    </div>
                )
            }
        } else {
            return (
                <p>Sorry, your browser does not support Web Storage.</p>
            )

        }
    }

    function onClose() {
        setState(prevState => ({
            ...prevState,
            show_bp_agent_warning: false,
            alert_loading: false
        }))
    }

    function refreshGrid() {
        setState(new_state => ({
            ...new_state,
            refresh_count: new_state.refresh_count + 1,
        }));

    }
    function toggleState() {
        setState({
            ...state,
            open: !state.open,
            branch_name: null,
        });
    }
    function toggleChildState(name, value) {
        setState((new_state) => ({
            ...new_state,
            [name]: value,
        }));
    }
    const toggleTagState = () => {
        var custom_tag_flag = state.open_custom;
        if (custom_tag_flag) {
            setState({
                ...state,
                loading_tag: true,
                tag: null
            });
            setTimeout(() => {
                setState({
                    ...state,
                    open_custom: !state.open_custom,
                    loading_tag: false,
                    tag: null
                });
            }, 200);
        } else {
            setState({
                ...state,
                loading_tag: true,
                custom_tag: null
            });
            setTimeout(() => {
                setState({
                    ...state,
                    open_custom: !state.open_custom,
                    loading_tag: false,
                    custom_tag: null
                });
            }, 200);
        }

    }



    const onKeyValueChangeHandler = (k, v, name) => {
        console.log(k, v, name, "sdbhchusbchbshbc")
        setState((new_state) => ({
            ...new_state,
            child: {
                ...new_state.child,
                [name]: {
                    ...new_state.child[name],
                    [k]: v,
                }
            },
            errors: { ...state.errors, [k]: "" }
        }));
    }

    console.log(state, "sdbhchusbchbshbc")

    const onParentKeyValueChangeHandler = (k, v) => {
        setState((new_state) => ({
            ...new_state,
            [k]: v,
            errors: { ...state.errors, [k]: "" }
        }));
    }


    const evaluateAgentStatusOnTrigger = (deployTag) => {
        console.log(deployTag, 'inside_function')
        const resultOfEvalution = evaluateAgentStatus(null, 'build')
        console.log(resultOfEvalution, 'bp_agent_state_ev_fun_d001');
        if (resultOfEvalution && resultOfEvalution.type === "unHealthy") {
            console.log("function_running")
            setState(prevState => ({
                ...prevState,
                show_bp_agent_warning: true,
                type_of_action: deployTag === 'DEPLOY_THIS_TAG' ? 'deploy-too' : 'only-build'
            }))
        }
        else {
            deployTag === 'DEPLOY_THIS_TAG' ? validateAndTrigger("DEPLOY_THIS_TAG") : validateAndTrigger();
        }
    }
    console.log(state, "fdajsdja")
    const onChangeToggleState = (e) => {
        console.log(state, e.target.name, "yyy")
        const name = e.target.name;
        if (name == 'child_build_override') {
            let childBuildStates = {};
            state.child_build_list.map((item) => {
                childBuildStates[item.type_label] = {
                    no_cache: false,
                };
            });
            setState((new_state) => ({
                ...new_state,
                child: {
                    ...new_state.child,
                    ...childBuildStates,
                }
            }))
        }
        setState(new_state => ({
            ...new_state,
            [name]: !new_state[name],
        }));
    }
    function onChangeHandlerForKeyValue(key, value) {

        if (key == "deployment_name") {
            value.forEach((id, index) => {
                value[index] = id;
            });
        }
        setState({
            ...state, [key]: value,
            errors: { ...state.errors, [key]: "" }
        });
    }



    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickClosePopover = () => {
        setAnchorEl(null);
    };

    const OpenPopover = Boolean(anchorEl);
    const id = OpenPopover ? 'simple-popover' : undefined;

    console.log(all_available_deployments, deploy_env_offline_flag, "svbhsbvhbshvbshfvb")




    return (

        <>

            <Dialog
                open={build_action_card}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                className="integrations-dialog action_card_dialog_width"
                style={{ overflow: "visible", height: "100vh", transition: "0.2s all ease-in-out" }}
                aria-describedby="alert-dialog-description"
            >
                <button
                    className='btn float-cancel-button'
                    onClick={props.closebox}
                    style={{ left: "-54px" }}
                >
                    <span className='ri-close-line'></span>
                </button>


                {/* this is the header of the this card */}
                <div className='card-header-new'>
                    <div className='d-flex align-center space-between' style={{ width: '100%' }}>
                        <div className='d-flex align-center'>
                            <span className='font-18 ml-5 d-inline-block font-weight-600' style={{ color: '#fff', lineHeight: "21.94px" }}>
                                Trigger a new Build
                            </span>
                        </div>
                    </div>
                </div>

                {
                    state.branch_loading ?
                        <Loading varient="light" />
                        :
                        // if he has the permission to trigger the build
                        is_permited ?
                            <>
                                <div style={{ overflowY: "scroll", height: "100%", padding: "70px 16px", display: "flex", alignItems: "start", flexDirection: "column", gap: "20px", width: "100%" }} className='d-flex'>
                                    {
                                        configuration_data && configuration_data.env_ci_detail && Object.keys(configuration_data.env_ci_detail).length > 0 ?

                                            <>
                                                <div className='card-new' style={{ width: "inherit", height: "max-content" }}>

                                                    <div className='d-flex-top' style={{ padding: "12px 16px 12px 16px", borderBottom: "1px solid #E6E6E6", height: "48px", width: "100%" }}>
                                                        <div className='d-flex'>
                                                            <span className='ri-tools-line' style={{ fontSize: "24px", lineHeight: "1" }}></span>
                                                            <p style={{ color: '#2F2F2F', fontSize: "14px", fontWeight: '700', marginLeft: "6px" }}>MAIN BUILD</p>
                                                        </div>
                                                        {configuration_data && configuration_data.env_ci_detail ?
                                                            <Input
                                                                type="switch"
                                                                padding="0px"
                                                                name="docker_cache"
                                                                label="Docker Cache"
                                                                data={state}
                                                                onChangeHandler={onChangeHandlerForDockerCache}
                                                                error={{}}
                                                            /> : null}

                                                    </div>
                                                    <BpAgentStatusActionAlert open={state.show_bp_agent_warning} onClose={onClose} onTrigger={state.type_of_action === 'only-build' ? () => validateAndTrigger() : () => validateAndTrigger("DEPLOY_THIS_TAG")} type='Build Agent' />

                                                    {/* this is body of the card */}
                                                    <div className='card-body-new' style={{ position: "relative" }}>
                                                        <>
                                                            <>
                                                                {
                                                                    state.loading_tag ?
                                                                        <>
                                                                            <Loading varient="light" style={{
                                                                                height: "40px",
                                                                                width: "48%",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                                position: "absolute",

                                                                            }} />

                                                                            <div style={{ height: "40px", width: "48%" }}></div>

                                                                        </> :
                                                                        <div style={{ width: "49%", }}>
                                                                            <Input
                                                                                type={"text-new"}
                                                                                height="41px"
                                                                                name={state.open_custom ? "custom_tag" : "tag"}
                                                                                placeholder={state.open_custom ? "custom-tag" : "Enter Tag"}
                                                                                subHeading={"(Optional)"}
                                                                                label={state.open_custom ? "Enter Custom Tag" : "Enter Tag"}
                                                                                mandatory
                                                                                data={{ tag: state.tag }}
                                                                                error={{ tag: state.errors?.tag }}
                                                                                onChangeHandler={onTagChangeHandler}
                                                                                extraDiv={<p className="mr-0 cursor-pointer text-anchor-blue pr-0" onClick={toggleTagState}>{state.open_custom ? "Choose Tag" : "Provide Custom Tag"}</p>}
                                                                            />
                                                                        </div>
                                                                }
                                                                {
                                                                    // state.branch_loading ? <Loading varient="light" /> :
                                                                    <div className='section-input d-block' style={{ width: "49%" }}>
                                                                        <Input
                                                                            style={{ height: "41px", width: "100%" }}
                                                                            type={state.open ? "text" : "auto-complete-dropdown"}
                                                                            name="branch_name"
                                                                            placeholder="Ex. Master"
                                                                            subHeading="(Optional)"
                                                                            getOptionLabel={(option) => option.label}
                                                                            list={state.branch_list && state.branch_list.length > 0 ? state.branch_list : []}
                                                                            label="Branch Name"
                                                                            data={{ branch_name: state.branch_name }}
                                                                            error={{ branch_name: state.errors.branch_name }}
                                                                            onChangeHandler={state.open ? onChangeHandler : onParentKeyValueChangeHandler}
                                                                            extraDiv={<p className="mr-0 cursor-pointer text-anchor-blue pr-0" onClick={toggleState}>{state.open ? "Choose Branch" : "Provide Custom Branch"}</p>}
                                                                        />
                                                                    </div>
                                                                }

                                                                {checkForFeatureTag()}

                                                            </>
                                                        </>

                                                    </div>
                                                    {
                                                        all_available_deployments && all_available_deployments.length > 1 &&
                                                        <div style={{ padding: "0px 16px", width: "inherit" }}>
                                                            <div className='card m-10' style={{ width: '100%' }}>
                                                                <div className={`${state.build_and_deploy ? "card-header border-bottom" : "card-header"}`} style={{ width: '100%', backgroundColor: 'white' }}>
                                                                    <p className='font-12'>Do you want to deploy this tag?</p>
                                                                    <Input
                                                                        type="switch"
                                                                        padding="0px"
                                                                        name="build_and_deploy"
                                                                        label=""
                                                                        data={{ is_deploy: state.is_deploy }}
                                                                        onChangeHandler={onChangeToggleState}
                                                                        error={{ is_deploy: state.is_deploy }}
                                                                    />


                                                                </div>
                                                                {
                                                                    state.build_and_deploy &&
                                                                    <div className='card-body pd-10'>
                                                                        <Input
                                                                            type="checkbox"
                                                                            name="deployment_name"
                                                                            label="Available Deployments"
                                                                            mandatorySign
                                                                            data={{ deployment_name: state.deployment_name }}
                                                                            error={{ deployment_name: state.errors.deployment_name }}
                                                                            list={all_available_deployments.length > 0 ? all_available_deployments : []}
                                                                            onChangeHandler={onChangeHandlerForKeyValue}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        multiple_build_enabled && ci_data && ci_data.length > 1 &&
                                                        <div style={{ padding: "0 16px", width: "100%" }}>
                                                            <div className='child-build-branch-div'>
                                                                <div className='d-flex space-between'>
                                                                    <div className='d-flex align-center'>
                                                                        <span className='ri-information-line' style={{ fontSize: "24px", lineHeight: "1", color: "#124D9B" }}></span>
                                                                        <p className='header-text'>Along with the main build secondary builds will also be performed. Override branch name?</p>
                                                                    </div>
                                                                    <div style={{ marginTop: "3px" }}>
                                                                        <Input
                                                                            type="switch"
                                                                            padding="0px"
                                                                            name="child_build_override"
                                                                            label=""
                                                                            data={{}}
                                                                            onChangeHandler={onChangeToggleState}
                                                                            error={{}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {
                                                                    state.child_build_override ?
                                                                        <div className='override-parent-div'>
                                                                            {
                                                                                state.child_build_list?.map((item, index) => {
                                                                                    return (
                                                                                        <div key={item.type_label} className='single-override-div' style={{ marginTop: "16px" }}>
                                                                                            <div className='d-flex align-center' style={{ width: "100%" }}>
                                                                                                <div style={{ textWrap: "nowrap" }}>
                                                                                                    <p className='child-build-header'>Child build-{item.type_label}</p>
                                                                                                </div>
                                                                                                <div className='hr-line'></div>
                                                                                            </div>
                                                                                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "16px" }}>
                                                                                                <div className='section-input d-block' style={{ width: "85%" }}>
                                                                                                    <Input
                                                                                                        style={{ height: "41px", width: "100%" }}
                                                                                                        type={state[item.type_label] ? "text" : "auto-complete-dropdown"}
                                                                                                        name="branch_name"
                                                                                                        placeholder="Ex. Master"
                                                                                                        subHeading="(Optional)"
                                                                                                        getOptionLabel={(option) => option.label}
                                                                                                        list={state.branch_list && state.branch_list.length > 0 ? state.branch_list : []}
                                                                                                        label="Branch Name"
                                                                                                        data={{}}
                                                                                                        error={{ branch_name: state.errors.branch_name }}
                                                                                                        onChangeHandler={state[item.type_label] ? (e) => onChildChangeHandler(e, item.type_label) : (k, v) => onKeyValueChangeHandler(k, v, item.type_label)}
                                                                                                        extraDiv={<p className="mr-0 cursor-pointer text-anchor-blue pr-0" onClick={state[item.type_label] ? () => toggleChildState(item.type_label, false) : () => toggleChildState(item.type_label, true)}>{state[item.type_label] ? "Choose Branch" : "Provide Custom Branch"}</p>}
                                                                                                    />
                                                                                                </div>
                                                                                                <Input
                                                                                                    type="switch"
                                                                                                    padding="0px"
                                                                                                    name="docker_cache"
                                                                                                    label="Docker Cache"
                                                                                                    data={state.child[item.type_label] && state.child[item.type_label].no_cache ? state.child[item.type_label].no_cache : {}}
                                                                                                    onChangeHandler={(e) => onChildCacheChangeHandler(e, item.type_label)}
                                                                                                    error={{}}
                                                                                                    varient="child-build"
                                                                                                />
                                                                                            </div>
                                                                                        </div>

                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='d-flex align-center space-between'
                                                        style={{ padding: '0px 16px', width: "100%" }}
                                                    >
                                                        <p className=" font-12 d-flex align-center" style={{ gap: "5px" }}>

                                                            <div className='d-flex align-center' style={{ gap: "5px" }}>
                                                                <span className="chip-sq dev-chip" style={{ textTransform: "uppercase" }}>
                                                                    {top_summary_data ?
                                                                        top_summary_data.type :
                                                                        getEnvNamefromId().env_type ? getEnvNamefromId().env_type : null}
                                                                </span>
                                                                <span className='ri-arrow-right-s-line' style={{ fontSize: "20px" }}></span>
                                                            </div>
                                                            <span className='d-flex align-center' style={{ verticalAlign: 'middle', gap: "5px", color: "#787878" }}>Sub Env: <span className='text-grey-new d-flex align-center'>
                                                                {top_summary_data ?
                                                                    top_summary_data.env_name :
                                                                    getEnvNamefromId().env_name ? getEnvNamefromId().env_name : null}</span>
                                                                <Tooltip title="This is the environment where the build will be triggered" placement="top">
                                                                    <span className='ri-information-line' style={{ fontSize: "17px" }}></span>
                                                                </Tooltip>
                                                            </span>

                                                        </p>
                                                        <div className="d-flex align-center" style={{ gap: "10px" }}>
                                                            {configuration_data && configuration_data.env_ci_detail ?
                                                                <>
                                                                    <button className="btn-cancel-blue" style={{ padding: '1rem', width: "84px", height: "40px" }} onClick={props.closebox} >Cancel</button>

                                                                </> : null}

                                                            {(configuration_data && configuration_data.env_ci_detail) &&
                                                                <>
                                                                    {state.alert_loading ?
                                                                        <GenericSkeleton width={168} height={39} style={{ borderRadius: '6px' }} rootStyle={{ flex: 0 }} />
                                                                        :
                                                                        <button
                                                                            className={state.build_and_deploy ? 'btn d-flex align-center gap-6 color-orange-btn' : 'btn d-flex align-center gap-6 color-blue-new'}
                                                                            style={{ padding: '0.5rem 1rem', height: "40px", color: "white", margin: "0px" }}
                                                                            onClick={
                                                                                state.build_and_deploy ?
                                                                                    () => { evaluateAgentStatusOnTrigger('DEPLOY_THIS_TAG') }
                                                                                    :
                                                                                    evaluateAgentStatusOnTrigger} >
                                                                            <span
                                                                                className={state.build_and_deploy ? 'ri-settings-3-line font-24' : 'ri-play-circle-line font-24'}></span>
                                                                            {
                                                                                state.build_and_deploy ?
                                                                                    "Trigger Build & Deploy" :
                                                                                    "Trigger Build"
                                                                            } &nbsp;

                                                                        </button> 
                                                                    }

                                                                </>

                                                            }

                                                            {
                                                                all_available_deployments && all_available_deployments.length == 1 && (configuration_data && configuration_data.env_ci_detail) &&
                                                                <>
                                                                    {
                                                                        deploy_env_offline_flag && deploy_env_offline_flag.value && deploy_env_offline_flag.value === "true" ?
                                                                            (top_summary_data && top_summary_data.is_env_down) || (is_env_down) ?
                                                                                <button className="btn d-flex align-center gap-6 color-orange-btn" style={{ padding: '0.5rem 1rem', height: "40px", color: "white", margin: "0px" }} onClick={handleClickOpenPopover} >

                                                                                    <span className='ri-settings-3-line' style={{ fontSize: "27px" }}></span>
                                                                                    Trigger Build &amp; Deploy &nbsp; </button>
                                                                                :
                                                                                <button className="btn d-flex align-center gap-6 color-orange-btn" style={{ padding: '0.5rem 1rem', height: "40px", color: "white", margin: "0px" }} onClick={() => { evaluateAgentStatusOnTrigger('DEPLOY_THIS_TAG') }} >
                                                                                    <span className='ri-settings-3-line'></span>
                                                                                    Trigger Build &amp; Deploy &nbsp; </button>
                                                                            :
                                                                            <button className="btn d-flex align-center gap-6 color-orange-btn" style={{ padding: '0.5rem 1rem', height: "40px", color: "white", margin: "0px" }} onClick={() => { evaluateAgentStatusOnTrigger('DEPLOY_THIS_TAG') }} >
                                                                                <span className='ri-settings-3-line font-24'></span>
                                                                                Trigger Build &amp; Deploy &nbsp; </button>
                                                                    }

                                                                </>

                                                            }
                                                            <BuildFlowActionAlert
                                                                onClose={() => setState(prevState => ({ ...prevState, parallBuildFeatureData: null }))}
                                                                onTrigger={triggerPipelineFromActionDialouge}
                                                                buidFlowData={state.parallBuildFeatureData}

                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <p className="font-12 text-center pd-10 mt-12 mb-12" style={{ width: "100%", color: "crimson" }}>Build Details not configured</p>
                                    }


                                    <div style={{ background: "#F4F4F4", width: "inherit" }}>

                                        {
                                            // state.branch_loading ? <div style={{ height: '195.5px' }}><Loading varient="light" /></div> :
                                            <CurrentProgressDataNew
                                                top_summary_data={top_summary_data}
                                                is_env_down={top_summary_data && top_summary_data.is_env_down ? top_summary_data.is_env_down : is_env_down ? is_env_down : null}
                                                type={type}
                                                recent_history={configuration_data}
                                                service_id={service_id}
                                                service_data={service_data}
                                                build_status={state.build_status}
                                                refresh={refreshGrid}
                                                closebox={props.closebox}
                                                refresh_count={state.refresh_count}
                                                branch_with_commit_sha={state.branch_with_commit_sha}
                                                application_id={props.application_id}
                                                service_env_name={service_env_name}
                                                env_id={env_id}
                                                serviceEnv={serviceEnv}
                                                ci_data={ci_data}
                                            />
                                        }
                                    </div>
                                </div>
                            </>
                            :

                            <div style={{ margin: '15px', height: "100%", width: "100%", justifyContent: "center !important", gap: "10px", fontSize: "20px" }} className='d-flex align-center justify-center' >
                                <h3 className="popup-card-main-heading pd-10" style={{ fontSize: "17px", color: "crimson" }}>
                                    You are not allowed to perform this action, Contact Project Admin
                                </h3>
                                <div>
                                    <IconButton onClick={props.closebox} >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                }
                <div className='build-card-bottom'>
                    <div>
                        <button onClick={props.closebox}>Close</button>
                    </div>
                </div>




                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={state.trigger === "success" || state.trigger === "failed"}
                    onClose={handleClose}
                    className="z-index-top"
                    autoHideDuration={3000}>

                    {
                        state.trigger === "success" ?
                            <>
                                <Alert severity="success" >
                                    {"SUCCESSFULLY SCHEDULED " + type}
                                </Alert>
                            </> : state.trigger == "failed" ?
                                <Alert severity="error" >
                                    {type + " SCHEDULE FAILED"}
                                    <div className="error-div">
                                        {state.error_msg}
                                    </div>
                                </Alert>

                                : null
                    }
                </Snackbar>
            </Dialog >

            <Popover
                id={id}
                open={OpenPopover}
                anchorEl={anchorEl}
                onClose={handleClickClosePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="popover-content" style={{ padding: '20px', width: '400px', }}>

                    <div className='' style={{ width: '100%', position: 'relative' }}>
                        <IconButton className='btn btn-icon' style={{ position: "absolute", right: '10px', top: '0px' }} onClick={handleClickClosePopover}><CloseIcon className='font-18' style={{ color: '#0086ff', }} /></IconButton>
                        <InfoIcon style={{ color: '#0086ff', width: '100%' }} className='font-48 mb-10 text-center' />
                        <p className='font-18 font-weight-bold text-center mb-5' style={{ color: '#0086ff', width: '100%' }}>ENVIRONMENT IS OFFLINE</p>
                        <p className='font-12 font-weight-400 text-center' style={{ color: '#787878', width: '100%' }}>Please note you cannot trigger build as the envrionment is offline now.
                            Please contact your team administrator to get the environment online.</p>
                    </div>

                </div>
            </Popover>
        </>

    )
    const useStyles = makeStyles((theme) => ({
        success: {
            backgroundColor: theme.palette.success.main, // Customize the color for success
        },
        error: {
            backgroundColor: theme.palette.error.main, // Customize the color for error
        },
    }));

}
export default TriggerActionCardNew;