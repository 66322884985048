import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import SquareAvatar from '../../../components/genericComponents/AvatarSquare';
import Delete from '../../../components/genericComponents/Delete';
import { Dialog, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { ExandableComponentList } from '../../../components/hoc/expandableList';
import properties from '../../../properties/properties';
import GenerateURL from '../../../util/APIUrlProvider';
import SendForApprovalConfirmationDialog from '../../service/detail/components/auditHistory/SendForApprovalConfirmationDialog';
import 'remixicon/fonts/remixicon.css'
import useFetchPermissions from '../../../components/customHooks/useFetchPermissions';
const JobListingCardNew = props => {
    const classes = useStyles();
    const permisisons = useFetchPermissions();
    const data = props.data;
    const { application_id } = useParams();
    var job_template_list = [];
    let selected_job_template_id = props.selected_job_template_id;
    var job_template_tags_list = [];
    var expandable_component = null;
    var expandable_component_tags = null;
    const iteration_count = 2;
    const iteration_count_tags = 5;
    const changeDefaultJobTemplate = props.changeDefaultJobTemplate ? props.changeDefaultJobTemplate : () => { }
    if (data.job_template.jobs && data.job_template.jobs.length > 0) {
        data.job_template.jobs.forEach((job, key) => {
            job_template_list.push(
                <Tooltip title={job.job_name ? job.job_name : "N/A"}>
                    <div className={"chip-sq chip-sq-primary-outline"} key={job.job_name ? job.job_name : "N/A"}>
                        <Link >{job.job_name ? job.job_name : "N/A"}</Link>
                    </div>
                </Tooltip>
            )
        })
        expandable_component = (
            <>
                +{data.job_template && data.job_template.jobs ? data.job_template.jobs.length - iteration_count : null}
            </>
        );
    }

    if (data.job_template_tag && data.job_template_tag.length > 0) {
        console.log(data,"datadatadata")
        data.job_template_tag.forEach((tag, key) => {
            console.log(data,"datadatadata")
            job_template_tags_list.push(
                <Tooltip title={tag ? tag : "N/A"}>
                    <div className={"chip-sq chip-sq-primary-outline"} key={tag ? tag : "N/A"}>
                        <Link >{tag ? tag : "N/A"}</Link>
                    </div>
                </Tooltip>
            )
        })
        expandable_component_tags = (
            <>
                +{data && data.job_template_tag ? data.job_template_tag.length - iteration_count_tags : null}
            </>
        );
    }

    var url = GenerateURL({ template_id: data.id }, properties.api.get_single_global_job_template, true);
   
    const is_permitted = permisisons?.POST.includes(url);

   
    const [openDialog, setOpenDialog] = useState(false);


    const handleCheckBox = (e) => {
        if (e.target.checked) {
            localStorage.setItem("openSetDefaultBox", "false")
        } else {
            localStorage.setItem("openSetDefaultBox", "true")
        }
    }


    


    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleSetAsDefault = (dataId) => {
        if (localStorage.getItem("openSetDefaultBox") === "false") {
            changeDefaultJobTemplate(data?.id)
            return;
        } else {
            setOpenDialog(true);
        }
    }
    const handleSetAsDefaultYes = () => {
        changeDefaultJobTemplate(data?.id)
    }

    return (
        <>
            <div className='d-flex'>
                <div className={classes.root + " " + "p-12 d-flex f-direction-column align-flex-start bg-white"} key={props.key}>
                    <div className="jobcardTop d-flex align-center space-between width-full">
                        <div className="jobcardtitledesc">
                            <div className="font-18">
                                {/*------------ this is the title of the data------------- */}
                                <Tooltip title={data.name ? data.name : 'N/A'}>
                                    <h3>{data.name}</h3>
                                </Tooltip>
                                {/* <Tooltip title={data.description ? data.description : 'N/A'}>
                            <p className="job-des lh-12 font-11 text-grey-83 mb-15 text-ellipsis-twoline">
                                {data.description}
                            </p>
                        </Tooltip> */}
                            </div>
                        </div>
                        {/* ---this is the menu item------- */}
                        <div className='jobcardmenubar'>
                            <div className="d-flex align-center" style={{ gap: '8px' }}>
                                <OptionList
                                    is_permitted={is_permitted}
                                    data={data}
                                    refreshFun={props.refreshFn}
                                    apiUrl={properties.api.get_single_global_job_template}
                                    
                                />
                            </div>
                        </div>
                    </div>
                    {/* ---------this is the all jobs------- */}
                    <div className='joblistingjobs d-flex align-start f-direction-column' >
                        <h4>Jobs</h4>
                        <div className='d-flex align-center' style={{ gap: "8px" }}>
                            <ExandableComponentList compoenent_list={job_template_list} iteration_count={iteration_count} expandable_component={expandable_component} variant={"joblistingview"} newStyle={""} />
                        </div>
                    </div>
                    <div className='joblistingjobs d-flex align-start f-direction-column' >
                        <h4>Tags</h4>
                        <div className='d-flex align-center' style={{ gap: "8px" }}>
                            {
                                job_template_tags_list && job_template_tags_list.length > 0 ?
                            <ExandableComponentList compoenent_list={job_template_tags_list} iteration_count={iteration_count_tags} expandable_component={expandable_component_tags} variant={"joblistingview"} newStyle={""} />
                                : <span className='font-12'>tags not available </span>
                            }
                        </div>
                    </div>

                    {/*--------------- this is the middle bar line ----------- */}
                    <div className="jobListingBottomBar">
                        <div className='jobmiddelbarline'>

                        </div>
                        <div className='jobcardbottomfetails d-flex'>
                           <div className='d-flex align-center'>
                           {data.template_version ?
                                // this is the user name section-------------
                                <span className="jobcardusername">Version: {data.template_version}</span> :null
                            }

                        {data.active ?
                                // this is the user name section-------------
                                <span className="jobcardusername active">Active</span> : <span className="jobcardusername inactive" >Inactive</span>
                            }
                           </div>
                            {/* this is the buttons default tag and setDefault BTN */}
                            {selected_job_template_id == data.id  ? <div className='job-default-template cursor-pointer' style={{
                                border: "1px solid  #FEA111",
                                background: "#FEA111",
                                color: "#fff"
                            }}>Default Template</div> : <div className='job-default-template cursor-pointer' onClick={() => handleSetAsDefault()}>Set as Default</div>}
                        </div>
                    </div>
                </div >
            </div>

            <Dialog open={openDialog}
                className={classes.muiSetDefaultDialog}
                onClose={handleCloseDialog}>
                <div className={classes.setDefaultDialog}>
                    <div className='w-100 align-center display-inline-flex space-between gap-8' style={{ padding: "11px 16px", background: "#fafafa" }}>
                        <div className='d-flex align-center justify-flex-start' style={{ gap: 24, }}>
                            <div className='font-weight-600 text-transform-uppercase word-wrap-break font-16' style={{ color: '#2F2F2F' }}>Caution</div>
                        </div>
                        <span className='ri-close-fill font-24 cursor-pointer' onClick={handleCloseDialog}>

                        </span>
                    </div>
                    <div className='setdefmiddlebar d-flex align-center justify-center f-direction-column w-100'>
                        <div  className="display-flex align-center justify-center"   style={{ padding: "12px", width: "56px", height: "56px", border: "1px solid #DFEDFF", background: "#F5FAFF", borderRadius: "6px" }}>
                            {/* <img src={properties?.tools?.groupIcon?.img ? properties?.tools?.groupIcon?.img : ""} >

                            </img> */}
                            <span className='ri-alert-fill' style={{ fontSize : "32px", color : "#0086FF"}}></span>
                        </div>
                        <div className='d-flex align-center f-direction-column mt-20' >
                            <div>
                                <div className='font-18 font-weight-600 word-wrap-break' style={{ color: '#2F2F2F' }}>Set as Default Template?</div>
                            </div>
                            <div>
                                <div className='font-14 font-weight-500 word-wrap-break text-align-center mt-12' style={{ color: '#505050', lineHeight: "1.5", width: "384px", height: "42px" }}>you’re going to set <b className="text-transform-uppercase"> “{data.name}” </b> as<br />Default Template. Are you Sure?</div>
                            </div>
                        </div>
                    </div>
                    <div className='setdefbottombar w-100 d-flex align-center space-between'>
                        <div className='d-flex align-center gap-8'>
                            <input type='checkbox' style={{ width: "18px", height: "18px", border : "1px solid #FFF !important" }} onChange={handleCheckBox}  />
                            <div className='font-weight-400 font-14 word-wrap-break' style={{ color: '#5C5C5C' }}>Don’t show this message again</div>
                        </div>
                        <div className='d-flex align-center gap-8'>
                            <button className='display-inline-flex align-center justify-center gap-5' onClick={handleCloseDialog}>
                                <span className='font-12 font-weight-600 word-wrap-break text-transform-uppercase text-align-center' >Cancel</span>
                            </button>
                            <button onClick={()=>{handleSetAsDefaultYes();
                                    handleCloseDialog();}} className='display-inline-flex align-center justify-center gap-5 border-none' style={{ height: "40px", width: "169px", background: '#0086FF', borderRadius: "6px" }}>
                                <span className='font-12 font-weight-600 word-wrap-break text-transform-uppercase color-white font-family-v1'  >Yes, Set as Default!</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog >
        </>
    )
}

// this is the option list component
const ITEM_HEIGHT = 48;
const OptionList = (props) => {
    
    const is_permitted = props.is_permitted;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const data = props.data ? props.data : {};

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    


    return (
        <>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <span className='ri-more-2-fill' style={{ fontSize: "20px" }}></span>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 'fit-content',
                    },
                }}
            >
                <MenuItem style={{ display: "grid" }}>
                    {
                        is_permitted &&
                        <Delete
                            varient="default"
                            display_data_name={data.name}
                            data={{ entity_id: data.id, name: "project_job_templates" }}
                            refresh={props.refreshFun}
                            api_link={GenerateURL({template_id: data.id }, properties.api.get_single_global_job_template)}
                        />
                    }
                </MenuItem>
                <MenuItem style={{ display: "grid", justifyContent: 'center' }}>
                    {
                        is_permitted ?
                            // <Link to={`/application/${application_id}/job-template/${data.id}/edit`} className="d-flex text-anchor-blue">
                            //     {/* <BorderColorIcon className="ml-10 vertical-bottom" /> */}
                            //     <button className='btn-sq-icon-primary mr-5'>
                            //         <span className='ri-edit-line vertical-align-super'></span>
                            //     </button>
                            // </Link>

                            <Link to={`/global/job/template/${data.id}/edit`} className="d-flex text-anchor-blue">
                                <span className='d-flex align-center' style={{ gap: "7px" }}><BorderColorIcon /><span style={{ marginRight: "7px" }}>Edit</span></span>
                            </Link>
                            :
                            <>
                          
                            <Tooltip title="You are not allowed to perform this action" >
                                <span className='d-flex align-center' style={{ gap: "7px" }}><BorderColorIcon /><span style={{ marginRight: "7px" }}>Edit</span></span>
                            </Tooltip>
                            </>
                    }
                </MenuItem>
            </Menu>
        </>
    )
}
const useStyles = makeStyles({

    muiSetDefaultDialog: {
        "& .MuiDialog-paperScrollPaper": {
            width: "inherit",
        },
        "& .MuiDialog-paper": {
            overflow: "hidden"
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "655px"
        },
    },
    setDefaultDialog: {
        width: "655px",
        height: "330px",

        "& .setdefmiddlebar": {
            padding: "24px 16px",
            height: "200px"


        },

        "& .setdefbottombar": {
            height: "75",
            padding: "16px",
            background: "rgb(250, 250, 250)",

            "& button": {
                "&:nth-child(1)": {
                    background: "white",
                    height: "40px",
                    width: "84px",
                    borderRadius: "6px",
                    border: "1px solid #9DC0EE",
                    color: '#124D9B' ,
                    "&:hover": {
                        background: "#0086FF",
                        color: "#FFF !important"
                    }
                },
                "&:nth-child(2)": {
                    "&:hover": {
                        background: "#03234D !important"
                    }
                }
            }
        }
    },
    root: {
        height: "260px",
        width: "100%",
        position: 'relative',
        overflow: 'visible',
        gap: "20px",
        flex: "1 0 0",
        padding: "12px",
        borderRadius: "6px",
        background: "#FFF",
        boxShadow: "0px 9px 8px 0px rgba(0, 0, 0, 0.06)",
        display: "flex",
        justifyContent: "space-between",

        "& a:visited": {
            color: "#787878 !important"
        },

        "& .jobcardTop": {
            height: "30px",
            "& .jobcardtitledesc": {
                "& h3": {
                    color: "#2F2F2F",
                    
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    textTransform: "uppercase",
                }
            },

        },

        "& .joblistingjobs": {
            gap: "8px",

            "& h4": {
                color: "#787878",
                
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal"
            },
        },

        "& .jobListingBottomBar": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            alignSelf: "stretch",
            flexDirection: "column",
            gap: "12px",

            "& .jobmiddelbarline": {
                height: "1px",
                backgroundColor: "#E3E3E3",
                width: "100%",
            },

            "& .jobcardbottomfetails": {
                width: "100%",
                dislay: "flex",
                alignItems: "center",
                justifyContent: "space-between",


                "& .jobcardusername": {
                    display: "flex",
                    padding: "6px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "6px",
                    borderRadius: "5px",
                    background: "#EBF5FF",
                    color: "#0086FF",
                    
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginLeft:'3px',
                    marginRight: '3px',
                    '&::first-child':{
                        marginLeft: '0px',
                    },
                    '&::last-child':{
                        marginRight: '0px',
                    },
                    '&.active':{
                        background: "#E6FBEA",
                        color: "#129E5B",
                    },
                    '&.inactive':{
                        background: "#F4F4F4",
                        color: "#797979",
                    }
                },

                "& .job-default-template": {
                    display: "flex",
                    width: "132px",
                    height: "36px",
                    padding: "6px 8px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "6px",
                    border: "1px solid  #E6E6E6",
                    color: "#505050",
                    
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",

                    "&:hover": {
                        background: "#FEA111",
                        color: "#fff",
                    }
                }
            }
        }

    },

    rootCard: {
        // marginLeft: "30px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
        // width : "215px",

        "& p": {
            color: "#000",
            fontSize: "13px",
        },

        '& .chip': {
            padding: '3px!important',
            display: 'inline-block'
        },
        '& .chip-yellow': {
            borderRadius: '3px',
            backgroundColor: '#ffc000',
            color: '#fff',
        },
        '& .chip-dark-grey': {
            borderRadius: '3px',
            backgroundColor: '#656565',
            color: '#fff',
        },
        '& .chip-green': {
            borderRadius: '3px',
            backgroundColor: '#69df9b',
            color: '#fff',
        },
    },
});

export default JobListingCardNew;