/* eslint-disable no-template-curly-in-string */
const baseURL = process.env.PUBLIC_API;
const workspaceURL = process.env.WORKSPACE_API;
const signup_partners_list = process.env.SIGNUP_PARTNER_LIST;
const release_version = process.env.REACT_APP_VERSION;
const mysql_version = process.env.REACT_APP_MYSQL_VERSION;
const redis_version = process.env.REACT_APP_REDIS_VERSION;
const public_api_version = process.env.REACT_APP_PUBLIC_API_VERSION;
const deploy_api_version = process.env.REACT_APP_DEPLOY_API_VERSION;
const scheduled_api_version = process.env.REACT_APP_SCHEDULED_API_VERSION;
const bp_agent_version = process.env.REACT_APP_BP_AGENT_VERSION;
console.log("jdhsdjkjhsj", process.env);
const default_signup_partners_list = [
]
const platform = process.env.PLATFORM;
const company_name = process.env.ORGANISATION_NAME ? process.env.ORGANISATION_NAME : "Opstree Solutions";
const company_logo = process.env.ORGANISATION_LOGO_URL ? process.env.ORGANISATION_LOGO_URL : null;
console.log(company_logo, "company_logocompany_logo")
const logo_width = process.env.LOGO_WIDTH ? process.env.LOGO_WIDTH : null;
const logo_height = process.env.LOGO_HEIGHT ? process.env.LOGO_HEIGHT : null;
const login_via = process.env.LOGIN_VIA && process.env.LOGIN_VIA == "USERNAME" ? "USERNAME" : "EMAIL"; ///process.env.LOGIN_VIA
const flag_for_feature_id = process.env.FLAG_FOR_FEATURE_ID && process.env.FLAG_FOR_FEATURE_ID == "TRUE" ? true : false
const sse_flag = false;
const aws_market_place_flag = process.env.AWS_MARKET_PLACE ? process.env.AWS_MARKET_PLACE : false;
const flag_for_run_params = process.env.FLAG_FOR_RUN_PARAMS_FIX && process.env.FLAG_FOR_RUN_PARAMS_FIX == "TRUE" ? true : false
const thirdPartyLoginFix = true;
const insight_url = process.env.INSIGHT_URL || null;
export default {
    api: {
        baseURL: process.env.NODE_ENV !== "development" ? baseURL :
            // "http://10.10.0.203:8000/",
            // "https://e0a7-122-176-151-79.ngrok-free.app/",
            //"http://192.168.21.205:8000/",
            //"http://129.154.249.102:9001/",
            //"http://192.168.21.178:8000/",
            //"http://129.154.249.102:9001/",
            //  "http://3.111.213.84:9001/",
            // "https://buildpiper-api.lenskart.com/",
            "http://127.0.0.1:8000/",
        //"http://192.168.21.178:8000/",
        //"https://api.buildpiper.io/",
        //"http://10.10.1.39:9001/",
        addUserURL: "api/v1/project/${application_id}/user/",
        delete_url: "api/v1/default/resource/delete/possible/",
        account_setting_url: "api/v1/user/",
        authenticating_url: "api/v1/user/login/",
        refreshTokenURL: "api/v1/user/login/refresh/",
        logoutURL: "api/v1/user/logout/",
        account_setting_url: "api/v1/user/",
        project_dashboard: "api/v1/project/dashboard/",
        cluster_dashboard_admin: "api/v1/project/${application_id}/dashboard/cluster/",
        env_dashboard_new: "api/v1/project/${application_id}/env/overview/",
        service_dahboard_data_url: "api/v1/service-dashboard/project/${application_id}/",
        service_dahboard_data_url_all: "api/v1/service-dashboard/project/${application_id}/?all=true",
        env_data_url: "api/v1/component/${service_id}/env/${env_id}/",
        application_data_url: "api/v1/project/",
        servicePerformanceData: 'api/v1/component/${service_id}/env/${env_id}/monitoring/service/',
        env_ci_cd_data_url: "api/v1/component/${service_id}/env/${env_id}/recent/activity/",
        env_monitoring_data_url: "api/v1/component/${service_id}/env/${env_id}/monitoring/infra/",
        service_basic_details_post_url: "api/v1/project/${application_id}/component/",
        all_services_listing_url: "api/v1/project/${application_id}/component/",
        service_basic_details_update_url: "api/v1/component/${service_id}/",
        service_basic_details_get_url: "api/v1/component/${service_id}/",
        env_data_post_url: "api/v1/component/${service_id}/env/",
        env_data_update_url: "api/v1/component/${service_id}/env/${env_id}/",
        jobTemplate_to_service: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/",
        configure_job_post_step_data: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/jobs/",
        configure_job_update_step_data: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/${component_env_id}/jobs/${id}/",
        configure_job_data: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/jobs/${job_code}/",
        update_configure_job_data: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/jobs/${job_code}/",
        create_configure_job_data: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/jobs/",
        build_trigger_for_job: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/jobs/${job_code}/trigger/",
        get_job_recent_trigger_data: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/jobs/${job_code}/trigger/recently/",
        get_services_for_pipeline_version_3_jobs: "api/v1/environment/${env_id}/job/template/${template_id}/job_code/${job_code}/components/",
        get_entity_resources: "api/v1/environment/${env_id}/job_template/${template_id}/job_code/${job_code}/entity/${entity_type}/entity_resource/",
        application_cluster_detail_url: "api/v1/project/${application_id}/cluster/",
        application_role_detail_url: "api/v1/project/${application_id}/role/",
        env_build_request_url: "api/v1/component/${service_id}/env/${env_id}/build/",
        env_deploy_request_url: "api/v1/component/${service_id}/env/${env_id}/cd/deploy/",
        is_pod_restart_permitted: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/deploy/pod_restart/",
        env_deploy_history_url: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/deploy/",
        env_single_deploy_request_url: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/deploy/",
        env_promote_request_url: "api/v1/component/${service_id}/env/${env_id}/promote/",
        env_build_revoke_url: "api/v1/component/${service_id}/env/${env_id}/build/${id}/revoke/",
        build_resume: "api/v1/component/${service_id}/env/${env_id}/build/${id}/resume/",
        integration_resume: "api/v1/component/${service_id}/env/${env_id}/integration/${id}/resume/",
        env_deploy_revoke_url: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/deploy/${id}/revoke/",
        deploy_resume: "api/v1/component/${service_id}/env/${env_id}/deploy/${id}/resume/",
        env_promote_revoke_url: "api/v1/component/${service_id}/env/${env_id}/promote/${id}/revoke/",
        promote_resume: "api/v1/component/${service_id}/env/${env_id}/promote/${id}/resume/",
        env_build_tags_list_url: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/available/tags/",
        permission_url: "api/v1/user/permission/",
        global_pipeline_save: "api/v1/pipeline/",
        global_pipeline_update: "api/v1/pipeline/${pipeline_id}/",
        global_recent_pipeline: "api/v1/pipeline/recent/activity/",
        global_pipelines_last_execution_list: "api/v1/pipeline/recent/activity/?all=true",
        pipeline_save_url: "api/v1/project/${application_id}/pipeline/",
        pipeline_update_url: "api/v1/pipeline/${pipeline_id}/",
        pipeline_get_url: "api/v1/pipeline/${pipeline_id}/",
        stages_get_url: "api/v1/pipeline/${pipeline_id}/stages/",
        stage_save_url: "api/v1/pipeline/${pipeline_id}/stages/",
        application_all_pipelines_last_execution: "api/v1/project/${application_id}/pipeline/recent/activity/",
        application_all_pipelines_last_execution_list: "api/v1/project/${application_id}/pipeline/recent/activity/?all=true",
        trigger_pipeline: "api/v1/pipeline/${pipeline_id}/trigger/",
        after_failure_resume: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/resume_after_failure/",
        pipeline_execution_detail: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/",
        pipeline_execution_detail_sse: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/stream/",
        task_service_details_sse: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/stream/",
        task_service_details: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/",
        component_task_details: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/component_task/${component_task_instance_id}/activity/",
        component_task_details_sse: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/component_task/${component_task_instance_id}/activity/stream/",
        configmap_task_details: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/configmap_task/${component_task_instance_id}/activity/",
        configmap_task_details_sse: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/configmap_task/${component_task_instance_id}/activity/stream/",
        secret_task_details: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/secret_task/${component_task_instance_id}/activity/",
        secret_task_details_sse: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/secret_task/${component_task_instance_id}/activity/stream/",
        approve_pipeline: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/approve/",
        component_logs: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/component_task/${component_task_instance_id}/log/",
        service_deployment_status: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/deploy/k8s/status/",
        service_deployment_pods_details: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/deploy/k8s/pods/",
        service_deployment_events: "api/v1/component/${service_id}/env/${env_id}/deploy/k8s/event/${pod_name}/",
        service_deployment_logs: "api/v1/component/${service_id}/env/${env_id}/deploy/k8s/log/${pod_name}/",
        predefined_hostpath: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/volumes/?type=host_path",
        predefined_emptydir: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/volumes/?type=empty_dir",
        get_ci_languages: "api/v1/ci/language/",
        saveCIBasicInfo: "api/v1/project/${application_id}/ci/",
        updateCIBasicInfo: "api/v1/ci/scope/${id}/",
        resume_pipeline: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/resume/",
        revoke_pipeline: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/revoke/",
        ci_stages: "api/v1/ci/language/${language_id}/stage/",
        post_ci_stages: "api/v1/ci/scope/${ci_scope_id}/language/${language_id}/configuration/",
        health_check: "api/v1/component/${service_id}/env/${env_id}/health/",
        health_check_new: "api/v1/default/health/",
        ci_scope: "api/v1/ci/scope/${scope_id}/",
        ci_scope_config: "api/v1/ci/scope/${scope_id}/language/${language_id}/configuration/",
        ci: "api/v1/project/${application_id}/ci/",
        ci_all: "api/v1/project/${application_id}/ci/list/",
        credential_all: "api/v1/credential/",
        credential: "api/v1/credential/${credential_id}/",
        oauth_user_login: "api/v1/oauth/login/",
        register_saas_user: "api/v1/user/register/",
        new_project_add: "api/v1/user/saas-project/",
        oauth_third_party: "api/v1/user/login/social/",
        project_status: "api/v1/default/celery/task/status/",
        monitoring_resource: "api/v1/monitoring_source/resource/",
        monitoring_resource_get: "api/v1/monitoring_source/",
        monitoring_resource_update: "api/v1/monitoring_source/${id}/",
        stability_matrix: "api/v1/monitoring_integration/",
        stability_matrix_update: "api/v1/monitoring_integration/${id}",

        stability_matrix_url: "api/v1/metric_group/",
        stability_matrix_url_single: "api/v1/metric_group/${id}/",
        stability_matrix_url_update: "api/v1/metric_group/${id}/",
        stability_matrix_url_delete: "api/v1/metric_group/${id}/",

        cluster_onboard: "admin/api/v1/cluster/onboard/",
        dr_cluster_onboard: "admin/api/v1/cluster/onboard/${cluster_id}/dr/",
        cluster_events: "admin/api/v1/cluster/${cluster_id}/kubernetes/events/",
        default_config_file_download: "api/v1/ci/language/${language_id}/stage/${stage_id}/config/file/",
        save_ingress: "admin/api/v1/cluster/${cluster_id}/ingress/",
        get_ingress: "admin/api/v1/cluster/${cluster_id}/ingress/${ingress_id}/",
        save_namespace: "admin/api/v1/cluster/${cluster_id}/namespace/",
        get_namespace: "admin/api/v1/cluster/${cluster_id}/namespace/${namespace_id}/",
        get_all_namespace: "admin/api/v1/cluster/${cluster_id}/namespace/?all=true",
        save_log_monitoring: "admin/api/v1/cluster/${cluster_id}/log/monitoring/",
        get_log_monitoring: "admin/api/v1/cluster/${cluster_id}/log/monitoring/${log_monitoring_id}/",
        save_infra_monitoring: "admin/api/v1/cluster/${cluster_id}/infra/monitoring/",
        get_infra_monitoring: "admin/api/v1/cluster/${cluster_id}/infra/monitoring/${monitoring_id}/",
        get_all_infra_monitoring: "admin/api/v1/cluster/${cluster_id}/infra/monitoring/",
        get_all_log_monitoring: "admin/api/v1/cluster/${cluster_id}/log/monitoring/",
        get_all_clusters: "admin/api/v1/cluster/",
        save_project: "admin/api/v1/project/",

        get_project: "admin/api/v1/project/${project_id}/",
        cluster_nodes: "admin/api/v1/cluster/${cluster_id}/kubernetes/nodes/",
        cluster_backup_storage_type: "admin/api/v1/backup/storage/type/",
        cluster_backup_entity_type: "admin/api/v1/backup/entity/type/",
        save_cluster_backup: "admin/api/v1/backup/",
        get_cluster_backup: "admin/api/v1/backup/${backup_id}/",
        cluster_summary: "admin/api/v1/cluster/${cluster_id}/kubernetes/summary/",
        get_namespaces_listing: "admin/api/v1/cluster/${cluster_id}/kubernetes/namespace/",
        get_namespace_resource_quota: "admin/api/v1/cluster/${cluster_id}/kubernetes/namespace/dev/resourcequota/",
        get_namespace_info: "admin/api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/",
        get_nodes_events: "admin/api/v1/cluster/${cluster_id}/kubernetes/nodes/${node_name}/events/",
        cluster_pods: "admin/api/v1/cluster/${cluster_id}/kubernetes/pods/",
        edit_ad_roles: "admin/api/v1/users/external_role/${edit_role}/",
        add_ad_roles: "admin/api/v1/users/external_role/",
        node_health_status: "admin/api/v1/cluster/${cluster_id}/kubernetes/nodes/${node_name}/infra/status/",
        get_ingress_listing: "admin/api/v1/cluster/${cluster_id}/kubernetes/ingress/",
        get_namespace_summary: "admin/api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/resourcequota/summary/",
        get_cluster_resources: "admin/api/v1/cluster/${cluster_id}/resource/",
        get_pods_info: "admin/api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/pods/${pod_name}/",
        get_pods_logs: "admin/api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/pods/${pod_name}/logs/",
        get_pods_events: "admin/api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/pods/${pod_name}/events/",
        get_pods_health: "admin/api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/pods/${pod_name}/resourcequota/summary/",
        get_cluster_status: "admin/api/v1/cluster/${cluster_id}/kubernetes/component/status/",
        get_cluster_health: "admin/api/v1/cluster/${cluster_id}/kubernetes/infra/status/",
        get_project_list: "admin/api/v1/project/",
        save_cluster_resources: "admin/api/v1/cluster/${cluster_id}/resource/",
        get_cluster_resource: "admin/api/v1/cluster/${cluster_id}/resource/${resource_id}/",
        post_cluster_data: "admin/api/v1/cluster/",
        get_single_cluster: "admin/api/v1/cluster/${cluster_id}/",
        get_backup_list: "admin/api/v1/backup/",
        get_backup: "admin/api/v1/backup/${backup_id}/history/",
        get_backup_logs: "api/v1/default/celery/task/log/",
        revoke_activity: "api/v1/default/celery/task/revoke/",
        git_test: "api/v1/default/git/test/", //for branch
        get_aws_regions: "admin/api/v1/default/aws/regions/",
        get_aws_zones: "admin/api/v1/default/aws/regions/${region}/zones/",
        get_aws_instances: "admin/api/v1/default/aws/regions/instance/types/",
        env_form_data: "api/v1/project/${project_id}/cluster/",
        associated_vm_data: "api/v1/project/${project_id}/vm_group/",
        save_env: "api/v1/project/${application_id}/env/",
        env_listing: "api/v1/project/${application_id}/env/",
        env_listing_all_true: "api/v1/project/${application_id}/env/?all=true",
        save_config: "api/v1/environment/${env_id}/configmap/",
        save_secret: "api/v1/environment/${env_id}/secret/",
        allConfigmaps: "api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/configmaps/",
        allSecrets: "api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/secrets/",
        get_configmap_keys: "api/v1/default/configmap_keys/${configmap_name}/",
        get_secrets_keys: "api/v1/default/secret_keys/${secret_name}/",
        get_env: "api/v1/environment/${env_id}/",
        secrets_listing: "api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/secrets/",
        config_listing: "api/v1/cluster/${cluster_id}/kubernetes/namespace/${namespace_name}/configmaps/",
        pvc_list: "api/v1/component/${service_id}/env/${component_env_id}/k8s/pvc/",
        save_autoscaling: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/hpa/",
        edit_autoscaling: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/hpa/${id}/",
        apply_time_based_default_hpa: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/hpa/?apply=true",
        apply_hpa: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/hpa/${id}/apply/",
        last_parent_ci: "api/v1/component/${component_id}/env/${env_id}/parent_build_history/",
        save_ci: "api/v1/component/${service_id}/env/${component_env_id}/ci/",
        get_ci_child: "api/v1/component/${service_id}/env/${component_env_id}/ci/?child=true",
        edit_ci: "api/v1/component/${service_id}/env/${component_env_id}/ci/${ci_id}/",
        save_cd: "api/v1/component/${service_id}/env/${component_env_id}/cd/",
        save_versioning: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/version/",
        edit_cd: "api/v1/component/${service_id}/env/${component_env_id}/cd/${id}/",
        save_side_cars: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/init_container/",
        edit_side_car: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/init_container/${side_car_id}/",
        single_side_car: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/init_container/${side_car_id}/",
        approval_req_url: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/request_approval/",
        approval_request: "api/v1/default/versioning/send_for_approval/",
        // confirm_approval : "api/v1/default/versioning/approve/",
        confirm_approval: "admin/api/v1/versioning/approve/",
        cd_single_object: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/",
        get_services_for_projectEnv: "api/v1/environment/${project_env_id}/components/?all=true",
        get_services_for_projectEnvV2: "api/v1/environment/${project_env_id}/components/?all=true&configure_version=v2&job_type=${job_type}",
        get_services_for_projectEnvV2_promote: "api/v1/environment/${project_env_id}/components/?all=true&configure_version=v2&job_type=${job_type}&source_env=${source_env}",
        get_secret_map: "api/v1/environment/${env_id}/secret/${secret_name}/?status=${secret_status}",
        get_config_map: "api/v1/environment/${env_id}/configmap/${config_name}/?status=${config_status}",
        deploy_config_map: "api/v1/environment/${env_id}/configmap/${config_name}/deploy/",
        deploy_secret: "api/v1/environment/${env_id}/secret/${secret_name}/deploy/",
        delete_config_map: "api/v1/environment/${env_id}/configmap/${config_name}/",
        delete_secret: "api/v1/environment/${env_id}/secret/${secret_name}/",
        get_clone_env_list: "api/v1/component/${service_id}/env/summary/",
        nexus_setup: "admin/api/v1/buildpiper/resource/",
        billing_registry: "admin/api/v1/tenant/info/",
        node_labels: "api/v1/component/${service_id}/env/${component_env_id}/k8s/nodes/labels/",
        health_status: "api/v1/default/celery/workers/status/",
        deployment_status: "api/v1/component/${service_id}/env/${component_env_id}/rollout/status/",
        deployment_status_yaml_view: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/deploy/k8s/status/",
        replicaset: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/deploy/k8s/replicaset/",
        get_docker_registry_type: "admin/api/v1/buildpiper/resource/type/",
        get_registry_details: "admin/api/v1/buildpiper/resource/${docker_registry_id}/",
        ingress_listing: "api/v1/cluster/${cluster_id}/ingress/",
        get_activities_details: "api/v1/default/activity/",
        get_current_activity_status: "api/v1/default/activity/${activity_id}/",
        get_activities_sse: "api/v1/default/activity/${activity_id}/stream/",
        get_activities_status: "api/v1/activity/",
        istio_save: "admin/api/v1/cluster/${cluster_id}/istio/",
        get_istio: "admin/api/v1/cluster/${cluster_id}/istio/${istio_id}/",
        istio_gateway_save: "admin/api/v1/cluster/${cluster_id}/istio/${istio_id}/gateway/",
        get_istio_gateway: "admin/api/v1/cluster/${cluster_id}/istio/${istio_id}/gateway/${gateway_id}/",
        get_gateway_list: "api/v1/cluster/${cluster_id}/gateway/",
        istio_addons: "admin/api/v1/cluster/${cluster_id}/istio/${istio_id}/istio-addon/",
        istio_addons_edit: "admin/api/v1/cluster/${cluster_id}/istio/${istio_id}/istio-addon/${addons_id}/",
        save_system_settings: "admin/api/v1/system_settings_value/",
        get_versioning_details: "api/v1/default/bp_versioning/enable/",
        system_settings_type: "admin/api/v1/default/system_property_type/?code=${code}",
        get_single_setting_data: "admin/api/v1/default/system_property_type/${id}/",
        update_system_settings: "admin/api/v1/default/update_property/",
        force_update_system_settings: "admin/api/v1/default/system_property_type/${id}/",
        fetch_system_settings: "admin/api/v1/system_settings_value/${id}/",
        system_settings: "api/v1/system_settings_value/",
        env_dashboard: "api/v1/environment/${env_id}/dashboard/",
        pod_restart: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/deploy/pod_restart/",
        pod_update: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/deploy/pod_scale/",
        pod_event: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/deploy/k8s/pods/${pod_name}/events/",
        pod_log: "api/v1/component/${service_id}/env/${component_env_id}/cd/${cd_id}/deploy/k8s/pods/${pod_name}/logs/",
        all_logs: "api/v1/default/celery/task/full/log/?task_id=${task_id}",
        start_stop_env: "api/v1/environment/${component_env_id}/control/",
        add_env_scheduler: "api/v1/environment/${component_env_id}/scheduler/",
        edit_env_scheduler: "api/v1/environment/${component_env_id}/scheduler/${scheduler_id}/",
        get_all_job_template: "api/v1/project/${application_id}/job/template/",
        get_global_job_template: "api/v1/job/template/",
        get_single_global_job_template: "api/v1/job/template/${template_id}/",
        add_job_template: "api/v1/project/${application_id}/job/template/",
        edit_template: "api/v1/project/${application_id}/job/template/${template_id}/",
        get_template_by_id: "api/v1/project/${application_id}/job/template/${template_id}/",
        workflow_data: "api/v1/project/${project_id}/job/template/",
        stepCatalogs: "api/v1/job/step/",
        getStep: "api/v1/job/step/${step_id}/",
        stepCatagory: "api/v1/job/step/category/",
        getStepDataRequirments: "api/v1/job/step/data_requirement/?all=true",
        retry_pipeline: "api/v1/pipeline/${pipeline_id}/trigger/${trigger_id}/retry/",
        rerun_job_after_failure: "api/v1/pipeline/${pipeline_id}/trigger/${trigger_id}/rerun_after_failure/",
        allow_promote: "api/v1/component/allowed-promote/",
        stepMount: "api/v1/job/step/mount_path/",
        canary_template_listing: "api/v1/project/${application_id}/release_template/",
        edit_canary_template_listing: "api/v1/project/${application_id}/release_template/${template_id}/",
        canary_progress: "api/v1/component/${component_id}/env/${component_env_id}/deploy/${deployment_id}/",
        trigger_next_phase: "api/v1/component/${component_id}/env/${component_env_id}/deploy/${deployment_id}/next_phase/",
        abort_phases: "api/v1/component/${component_id}/env/${component_env_id}/deploy/${deployment_id}/delete_deployment/",
        monitoring_adaptors: "api/v1/supported/monitoring_adaptor/",
        supported_metrices: "api/v1/supported/metrices/",
        multiple_super_admin_add: "admin/api/v1/user/super/add/",
        manifest_view_list: "api/v1/default/environment/${component_env_id}/fetch_manifest/",
        remove_template: "api/v1/project/${project_id}/job/template/${temp_id}/",
        getStepCatalog: "api/v1/job/step/?name=${step_catalog_code}",
        jiraPossiableFieldsValue: "api/v1/project/jira-transition/",
        master_envs: "api/v1/environment/master/",
        get_all_services_for_configMap: "api/v1/environment/${project_env_id}/configmap/${name}/services/",
        get_all_services_for_secret: "api/v1/environment/${project_env_id}/secret/${name}/services/",
        restart_services: "api/v1/default/restart/service/",
        add_init_container: "api/v1/component/${service_id}/env/${component_env_id}/cd/init_container/",
        get_task_metadata: "api/v1/default/celery/task/status/?task_id=${global_task_id}",
        sse_logs_load: "api/v1/default/celery/task/log/stream/",
        get_placeholders: "api/v1/default/supported/placeholders/",
        save_manifest_placeholder: "api/v1/project/${application_id}/key_replacement/",
        update_manifest_placeholder: "api/v1/project/${application_id}/key_replacement/${id}/",
        supported_env_variables: "api/v1/default/supported_env_variable/",
        ecr_token_refresh: "admin/api/v1/ecr_gcr/token/refresh/",
        sse_job_status_load: "api/v1/default/celery/task/status/stream/",
        forgot_password: "api/v1/user/password_reset/",
        forgot_password_reset: "api/v1/user/password_reset/confirm/",
        grep_search: "api/v1/default/celery/task/log/?task_id=${global_task_id}&",
        fetch_all_repo: "api/v1/git_repo/",  //for git
        fetch_single_repo_data: "api/v1/git_repo/${edit_id}/",
        add_jira_ids: "admin/api/v1/default/jira_integration/",
        ticketing_system: "api/v1/default/integration/",
        ticketing_system_update: "api/v1/default/integration/${id}/",
        update_jira_ids: "admin/api/v1/default/jira_integration/${id}/",
        jira_users: "api/v1/default/jira_user/?job_template_id=${template_id}&user=${user_query}&project=${issue_key}", //issue_key replaced to project_key as per backend team suggestion
        user_list: "admin/api/v1/users/",
        all_user_list: "admin/api/v1/users/?all=true",
        user_roles_and_projects: "admin/api/v1/users/${id}/",
        upload_user: "admin/api/v1/users/upload_user/",
        upload_user_group: "admin/api/v1/users/upload_group/",
        inactive_user: "admin/api/v1/users/${id}/inactivate/?activated=0",
        active_user: "admin/api/v1/users/${id}/inactivate/?activated=1",
        test_registry_connection: "admin/api/v1/buildpiper/resource/test/${edit_id}/",
        getIntegrationTypes: "api/v1/default/supported/integration/",
        getIntegrationVariables: "api/v1/default/supported/integration/${id}/",
        getIntegrationTypeValues: 'api/v1/default/supported/integration/${integration_type}/value-list/',
        get_trigger_time_modify_env_vars: "api/v1/component/${component_id}/env/${sub_env_id}/job_templates/jobs/${job_code}/modify_trigger_time_env_vars/",
        uploaded_group_list: "admin/api/v1/users/group/",
        sse_build_status: "api/v1/component/${component_id}/env/${env_id}/build/${build_id}/status/stream/",
        sse_deploy_status: "api/v1/component/${component_id}/env/${env_id}/deploy/${deploy_id}/status/stream/",
        sse_promote_status: "api/v1/component/${component_id}/env/${env_id}/promote/${promote_id}/status/stream/",
        sse_for_activity_status_execution_history: "api/v1/default/activity_status/stream/?task_id=${task_id}",
        sse_for_activity_step_status_execution_history: "api/v1/default/activity_step_status/stream/?task_id=${task_id}",
        sse_for_pipeline_status_refresh: "api/v1/project/${application_id}/pipeline/stream/",
        activity_page_sse: "api/v1/default/activity_status/stream/",
        integration_testing: "api/v1/component/${component_id}/env/${component_env_id}/integration_detail/",
        fetch_component_envs: "api/v1/component/${component_id}/env/?all=true",
        getCommandHookVisibility: "api/v1/default/hidden_hook/",
        get_issues_list: "api/v1/default/jira_issue/?job_template_id=${job_temp_id}&search_string=${search_string}",
        issue_type: "api/v1/default/issue_link_types/?job_template_id=${job_temp_id}",
        default_build_insight: "api/v1/default/insight/",
        build_insight_data: 'api/v1/insight/build_insight/?project=${project}&environment_master=${environment_master}&project_env=${project_env}&component=${component}&start_date=${start_date}&end_date=${end_date}&duration_type=${duration_type}',
        build_insight_trends: 'api/v1/insight/build_trend_insight/?project=${project}&environment_master=${environment_master}&project_env=${project_env}&component=${component}',
        all_project_list: 'api/v1/project/',
        saml_login: "api/v1/user/new_login/",
        acs: 'api/v1/user/new_acs/',
        add_permission: "admin/api/v1/permission/group/",
        permission_group_of_specific_entity_id: "admin/api/v1/permission/group/?entity_id=${entity_id}",
        delete_permission_group: "admin/api/v1/permission/group/${permission_id}/",
        entities_list: "admin/api/v1/default/activity/entity/",
        permissions_url: "admin/api/v1/permission/url/?entity_id=${entity_id}",
        edit_permission: "admin/api/v1/permission/group/${id}/?entity_id=${entity_id}",
        delete_permission: "admin/api/v1/permission/group/${id}/",
        event_listing: 'api/v1/git_repo/listener/',
        add_event: "api/v1/git_repo/${git_provider_id}/listener/",
        // post_edit_event_data: "api/v1/git_repo/webhook/${edit_id}/",
        post_edit_event_data: "api/v1/git_repo/listener/${listener_id}/",
        get_supported_events: "api/v1/git_repo/provider/${provider_id}/supported_events/",  // api/v1/git_repo/webhook_event_type/
        get_supported_actions: "api/v1/git_repo/webhook_action/",
        side_panel_data: "api/v1/project/dashboard/all/",
        closed_sse_from_backend: "api/v1/default/sse/status/set/",
        get_post_helm_chart: "api/v1/helm_support/helm_integration/",
        update_helm_chart: "api/v1/helm_support/helm_integration/${id}/",
        test_helm: "api/v1/default/helm_test/",
        fetch_helm_charts: "api/v1/default/helm_chart_names",
        helm_chart_versions: "api/v1/default/helm_versions/",


        build_workspace_url: "api/v1/component/${service_id}/env/${env_id}/${action_type}/${action_id}/workspace/?global_task_id=${global_task_id}",
        build_workspace_url_with_path: "api/v1/component/${service_id}/env/${env_id}/${action_type}/${action_id}/workspace/${path}/?global_task_id=${global_task_id}",
        build_workspace_download_file: "api/v1/component/${service_id}/env/${env_id}/${action_type}/${action_id}/workspace/${path}/?global_task_id=${global_task_id}&download=${download}",
        deploy_workspace_url: "api/v1/component/${service_id}/env/${env_id}/cd/${cd_id}/${action_type}/${action_id}/workspace/?global_task_id=${global_task_id}",
        inbound_events_url: "api/v1/default/webhookhistory/",
        inbound_events_details: "api/v1/default/webhookhistory/${event_id}/",
        inbound_action_details: "api/v1/default/webhookhistory/${event_id}/action/ ",
        check_existence_of_tag: "api/v1/component/${service_id}/env/${env_id}/check_available_tag/?tag_name=${tag_name}",
        save_user_group: "admin/api/v1/users/role_master/",
        edit_user_group: "admin/api/v1/users/role_master/${edit_id}/",
        get_policies: "admin/api/v1/permission/policy/",
        edit_policies: "admin/api/v1/permission/policy/${edit_id}/",
        get_policy_template: "admin/api/v1/permission/policy_template/",
        edit_policy_template: "admin/api/v1/permission/policy_template/${edit_id}/",
        permission_group_of_single_entity: "admin/api/v1/permission/url/?entity_id=${entity_id}",
        resource_filters: "admin/api/v1/permission/supported_filter/?entity_id=${entity_id}",
        add_policy_templates: "admin/api/v1/permission/policy_template/",
        priority_class: "api/v1/component/${component_id}/env/${env_id}/k8s/priorityclass/",
        set_dr_active: "admin/api/v1/cluster/onboard/${cluster_id}/dr/active/",
        set_dr_inactive: "admin/api/v1/cluster/onboard/${cluster_id}/dr/inactive/",
        get_policy_list: 'admin/api/v1/permission/policy/',
        get_role_list: 'admin/api/v1/users/role_master/',
        invite_user: 'admin/api/v1/users/invite/',
        update_admin_user_details: 'admin/api/v1/users/${id}/',
        celery_health_check_sse: "api/v1/default/celery/workers/status/stream/",
        secret_comparison_api: "api/v1/insight/deployment_insight/?project=${project}&environment_master=${environment_master}&project_env=${project_env}&component=${component}",
        deployment_insight_trends: 'api/v1/insight/deployment_insight/?project=${project}&environment_master=${environment_master}&project_env=${project_env}&component=${component}',
        deploy_insight_data: 'api/v1/insight/deploy_insight/?project=${project}&environment_master=${environment_master}&project_env=${project_env}&component=${component}&start_date=${start_date}&end_date=${end_date}&duration_type=${duration_type}',
        activity_master: 'api/v1/default/activity/master/',
        env_master: 'api/v1/default/env_master/',
        deployment_phase_sse: "api/v1/default/deployment/phase/status/stream/",
        create_default_job_template: 'api/v1/project/${application_id}/job/template/default-create/',
        workspace_url_for_html_view: "api/v1/component/${service_id}/env/${env_id}/${action_type}/${action_id}/html/workspace/?global_task_id=${global_task_id}",
        queue_list: "api/v1/default/celery/queue_name/?job_code=${job_code}",
        queue_enabled: "api/v1/default/celery/queue_name/enable/",
        buildEnvSse: "api/v1/default/celery/task/build/status/stream/",
        build_velocity_insight_data: 'api/v1/insight/build_insight/?start_date=${start_date}&end_date=${end_date}&duration_type=${duration_type}',
        deploy_velocity_insight_data: 'api/v1/insight/deploy_insight/?start_date=${start_date}&end_date=${end_date}&duration_type=${duration_type}',
        check_pipeline_is_rerunable: "api/v1/default/pipeline_rerun_support/${pipeline_id}/pipeline_instance/${pipeline_instance_id}/",
        jira_additional_fields: "api/v1/default/jira_additional_fields/",
        jira_issue_type: "api/v1/default/jira_issue_type/",
        jira_field_type: "api/v1/default/jira_field_type",
        save_database: "api/v1/user_databases/",
        get_supporeted_job_entity: "api/v1/default/supported_job_dependent_entity/",
        get_runtime_params_for_pipeline: "api/v1/pipeline/${pipeline_id}/runtime_job_variables/",
        get_runtime_params_for_dependent_job: "api/v1/environment/${env_id}/job_code/${job_code}/entity/${entity_type}/${entity_resource_id}/runtime_job_variables/",
        pipeline_services_list: "api/v1/rollback/pipeline/${pipeline_id}/",
        rollback_points: "api/v1/rollback/cd/${cd_id}/rollbackpoints/",
        rollback_details: "api/v1/rollback/compare/?component=${service_name}&project_env=${project_env}&project=${project}&environment_master=${env_master_name}&rollback=${rollback_id}&deploy=${current_deploy_id}&rollback_cd=${rollback_cd_id}&deploy_cd=${current_cd_id}",
        pipeline_rollback: "api/v1/rollback/pipeline/rollbackpoint/",
        get_all_Workers: "api/v1/default/worker_name/",
        is_pipeline_scheduling_enabled: "api/v1/default/approval_schedule/",
        get_jira_additional_group_list: "api/v1/default/jira_groups_list/?job_template_id=${job_template_id}",
        buildPossible: "api/v1/component/${service_id}/env/${env_id}/build/possible/",
        bussiness_fn: "api/v1/default/business-functions/",
        kubeConfigPermissions: "admin/api/v1/cluster/${cluster_id}/cluster_verbs/",
        metric_server_setup: "admin/api/v1/cluster/${cluster_id}/metric_server_setup/",
        get_subtask_issue_types: "api/v1/default/project_subTask_issue_types/?job_template_id=${job_template_id}",
        stability_matrix: "api/v1/monitoring_integration/",
        customVolumePaths: "api/v1/default/custom_volume_paths/",
        widget_list_v3: "api/v1/default/widget_list/",
        get_jira_ticket_status: "api/v1/default/status_of_jira/",
        //FOR WIDGET USE
        widget_data: "api/v1/component/${service_id}/env/${component_env_id}/widget/configuration/data/",
        service_deployment_status_widget: "api/v1/component/${service_id}/env/${env_id}/k8s/status/",
        service_deployment_status_widget_yaml: "api/v1/component/${service_id}/env/${component_env_id}/k8s/status/",
        replicaset_widget: "api/v1/component/${service_id}/env/${component_env_id}/k8s/replicaset/",
        service_deployment_pods_details_widget: "api/v1/component/${service_id}/env/${env_id}/k8s/pods/",
        pod_event_widget: "api/v1/component/${service_id}/env/${component_env_id}/k8s/pods/${pod_name}/events/",
        pod_log_widget: "api/v1/component/${service_id}/env/${component_env_id}/k8s/pods/${pod_name}/logs/",
        buildPossible: "api/v1/component/${service_id}/env/${env_id}/build/possible/",
        global_pipeline_save: "api/v1/pipeline/",
        global_pipeline_update: "api/v1/pipeline/${pipeline_id}/",
        global_recent_pipeline: "api/v1/pipeline/recent/activity/",
        global_pipelines_last_execution_list: "api/v1/pipeline/recent/activity/?all=true",
        all_global_pipelines_last_execution: "api/v1/pipeline/recent/activity/",
        get_all_job_template: "api/v1/project/${application_id}/job/template/",
        get_global_job_template: "api/v1/job/template/",
        get_single_global_job_template: "api/v1/job/template/${template_id}/",
        create_default_job_template: "api/v1/default/global/template/",
        addPipelineUserURL: "api/v1/pipeline/user/",
        configmap_task_details: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/configmap_task/${component_task_instance_id}/activity/",
        secret_task_details: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/secret_task/${component_task_instance_id}/activity/",
        is_pipeline_scheduling_enabled: "api/v1/default/approval_schedule/",
        get_global_template_of_version: "api/v1/job/template/${template_id}/?version=${version_id}",
        create_new_global_template_version: "api/v1/job/template/${template_id}/?new_version=True",
        global_template_version_active: "api/v1/job/template/${template_id}/?active=True",
        get_global_template_versions: "api/v1/job/template/${template_id}/?version_history=True",
        service_deployment_status_widget_yaml: "api/v1/component/${service_id}/env/${component_env_id}/k8s/status/",

        //Snow Integration
        snow_category_list: "api/v1/default/category_list/",
        snow_service_list: "api/v1/default/service_list/",
        conf_item_list: "api/v1/default/conf_items/",
        assignment_grp_list: "api/v1/default/assignment_groups/",
        create_vm_group: "api/v1/default/vm_group/",
        edit_vm_group: "api/v1/default/vm_group/${edit_id}/",
        add_single_vm: "api/v1/default/virtual_machine/",
        edit_single_vm: "api/v1/default/virtual_machine/${edit_id}/",
        fetch_public_key: "api/v1/default/fetch_key/",
        vm_group_csv_upload: "api/v1/default/virtual_machine/?csv=True",
        vm_health_check: "api/v1/default/health/check/",
        premiumFeatureQuery: "api/v1/default/contact_sales/",
        canary_metric_data: "api/v1/component/canary-analysis-metric-data/?canary_analysis_task_instance_id=${task_id}&service_deployment_key=${service_deployment_key}",
        canary_meta_data: "api/v1/component/canary-analysis-meta-data/?canary_analysis_task_instance_id=${task_id}&service_deployment_key=${service_deployment_key}",
        canary_template_list: "api/v1/canary_analysis/canary-analysis-template/",
        canary_next_phase: "api/v1/pipeline/${pipeline_id}/trigger/${pipeline_instance_id}/stage/${stage_instance_id}/task/${task_instance_id}/job_manager/?canary_analysis_task_instance_id=${canary_task_id}",
        jira_test: "api/v1/default/jira/test/",
        integration_test: "api/v1/default/integration_test/",
        premiumFeatureQuery: "api/v1/default/contact_sales/",
        save_lb: "api/v1/default/load_balancer/",
        edit_lb: "api/v1/default/load_balancer/${id}/",
        workspace_url_v3: "api/v1/component/${service_id}/env/${env_id}/job_templates/jobs/${job_name}/workspace/?global_task_id=${global_task_id}",
        workspace_url_v3_with_path: "api/v1/component/${service_id}/env/${env_id}/job_templates/jobs/${job_name}/workspace/${path}/?global_task_id=${global_task_id}",
        workspace_url_v3_download_file: "api/v1/component/${service_id}/env/${env_id}/job_templates/jobs/${job_name}/workspace/${path}/?global_task_id=${global_task_id}&download=${download}",
        jira_test: "api/v1/default/jira/test/",
        premiumFeatureQuery: "api/v1/default/contact_sales/",

        //Release Packages
        load_jira_tickets: "api/v1/default/load_issues/",
        fetch_all_components_for_env: "api/v1/default/components/",
        fetch_all_tags_from_component: "api/v1/default/load_tags/",
        fetch_all_branch_and_commit_for_artifact: "api/v1/default/fetch/branch_or_commit/",
        fetch_all_branches_from_component: "api/v1/default/component/load_branch/",
        fetch_all_commits_from_branch: "api/v1/default/load_commits/",
        save_release_package: "api/v1/release_package/",
        release_package_listing: "api/v1/default/release_package_listing/",
        project_listing: 'api/v1/default/release-project/',
        jira_integration_list: 'api/v1/default/jira_integration_list/',
        jira_issue_list: 'api/v1/default/load_issues/',
        component_search: 'api/v1/default/components/',
        load_component_branches: 'api/v1/default/component/load_branch/',
        load_commits: 'api/v1/default/load_commits/',
        load_tags: 'api/v1/default/load_tags/',
        load_sub_env: 'api/v1/default/load_sub-env/',
        release_package_listing_pipeline: 'api/v1/default/release_package-pipeline/',
        get_release_package: 'api/v1/release_package/${release_package_id}/',
        get_release_package_overview: 'api/v1/default/release_package_listing/${release_package_id}/',
        get_releases_list: 'api/v1/default/release_versions/?app_name=${app_name}',
        get_valid_stories: 'api/v1/default/release_validation/',
        mfa_login: 'api/v1/user/login/mfa/',
        mfa_token_resend: 'api/v1/user/login/mfa/re-send/',
        resource_kind: "api/v1/default/pod_logs/${component_env_id}/resource_kind_list/",
        password_expired: "api/v1/user/reset_password/",
        load_ado_sprints: "api/v1/default/ado/load_sprints/",
        ado_work_items: "api/v1/default/ado/work_items/sprints/",
        ado_move_work_items: "api/v1/default/ado/move_work_items/",
        load_ado_states: 'api/v1/default/ado/work_items/states/',
        total_state_count: 'api/v1/default/ado/work_item_states/count/',
        sprint_approve: 'api/v1/default/ado/approve/',
        ado_work_item_fetch: "api/v1/default/ado/load_work_items/id_or_title/", //id_or_title 
        get_permissoin_store_in_indexedDB_flag: "api/v1/default/permission-cache-update/",
        load_jira_projects: "api/v1/default/get_ticketing_projects/",
        fetch_component_envs_per_master: "api/v1/default/component_env/load_sub_env/",
        fetch_jira_priority_list: "api/v1/default/jira_priority/",
        fetch_job_template_based_component: "api/v1/default/job/template/${project_env_id}/service/${component_name}/"

    },

    languages: [
        {
            label: "JAVA",
            src: "/images/language_icons/java_L.png"
        },
        {
            label: "GOLANG",
            src: "/images/language_icons/golang_L.png"
        },
        {
            label: "PHP",
            src: "/images/language_icons/php_L.png"
        },
        {
            label: "PYTHON",
            src: "/images/language_icons/python_L.png"
        },
        {
            label: "NODEJS",
            src: "/images/language_icons/node_L.png"
        },
        {
            label: "OTHER",
            src: "/images/language_icons/otherlanguage_L.png"
        },
    ],
    registries_option: [
        {
            id: 4000,
            label: "Elastic Container Registry",
            src: "/images/registries_icon/registry_aws_logo.png"
        },
        {
            id: 5000,
            label: "Google Container Registry",
            src: "/images/registries_icon/gcr.png"
        },
        {
            id: 5000,
            label: "Google Container Registry",
            src: "/images/registries_icon/gcr.png"
        },
        {
            id: '',
            label: "Google Cloud",
            src: "/images/registries_icon/registry_google_logo.png"
        },
        {
            id: 3000,
            label: "Github Container Registry",
            src: "/images/registries_icon/azure.png"
        },
        {
            id: 7000,
            label: "Azure",
            src: "/images/azure.png"
        },
        {
            id: 1000,
            label: "Nexus",
            src: "/images/registries_icon/registry_nexus_logo.png"
        },
        {
            id: 2000,
            label: "Alibaba ACR Registry",
            src: "/images/registries_icon/registry_alibaba_logo.png"
        },
        {
            id: 6000,
            label: "Jfrog Registry",
            src: "/images/registries_icon/jfrog-icon.png"
        },
        {
            id: 8000,
            label: "Docker Hub",
            src: "/images/docker_hub.svg"
        },
        {
            id: 9000,
            label: "Others",
            src: '/images/registries_icon/others.svg'
        }
    ],
    repo_list: [
        {
            id: 4000,
            label: "Git",
            src: "/images/integrations/git.png"
        },
        {
            id: 3000,
            label: "Bitbucket",
            src: "/images/integrations/bitbucket.png"
        },
        {
            id: 1000,
            label: "Github",
            src: "/images/integrations/github.png"
        },
        {
            id: 2000,
            label: "Gitlab",
            src: "/images/integrations/gitlab.png"
        }
    ],
    catalog_images_new: [
        {
            "MAVEN_EXECUTE": "/images/step_catalog/maven.svg",
            "MAVEN_STEP": "/images/step_catalog/maven.svg",
            "BUILD_AMI_USING_PACKER": "/images/step_catalog/buildamiusingpacker.svg",
            "CODE_SCANNER": "/images/step_catalog/Scan_app_logo 1.png",
            "VM_CMD_EXECUTE": "/images/step_catalog/vm_cmd_execute.png",
            "TERRAFORM_APPLY": "/images/step_catalog/TF.svg",
            "TF LINT": "/images/step_catalog/TF.svg",
            "TF CHECKOV": "/images/step_catalog/TF.svg",
            "TF INFRACOST": "/images/step_catalog/TF.svg",
            "INTEGRATION_TEST": "/images/step_catalog/3fb7bc16d7af89847e1627d42cbff7f5 1.svg",
            "DEPLOYMENT_MANIFEST_CREATOR": "/images/step_catalog/logos_kubernetes.svg",
            "IMAGE_SIZE_VALIDATOR_STEP": "/images/step_catalog/imagesizevalidator.svg",
            "IMAGE_SIZE_VALIDATOR": "/images/step_catalog/imagesizevalidator.svg",
            "IMAGE_CLEANUP_STEP": "/images/step_catalog/image 1728.svg",
            "SECURE_COPY": "/images/step_catalog/securecopy.svg",
            "MANAGE_REMOTE_PROCESS": "/images/step_catalog/manageremoteaccess.png",
            "S3_FILE_DOWNLOAD": "/images/step_catalog/s3filedownload.svg",
            S3_UPLOAD: "/images/step_catalog/s3fileupload.svg",
            "S3_FILE_UPLOAD": "/images/step_catalog/s3fileupload.svg",
            "AWS budget Acceletor": "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            "Trivy-Image-Vuln-Scanning": "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            "Trivy-File-Secret-Scanning": "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            "Trivy-File-license-Scanning": "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            "Trivy-File-vuln-Scanning": "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            "trivy-image-testing": "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            AWS_NETWORK_SKELETON_STEP: "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            AWS_MSK_KAFKA_STEP: "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            AWS_EKS_STEP: "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            AWS_ELASTICACHE_STEP: "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            SONAR_SCANNER: "/images/step_catalog/sonar.svg",
            IMAGE_LAYER_VALIDATOR: "/images/step_catalog/imagesizevalidator.svg",
            K8S_DEPLOYMENT_MANIFEST_CREATOR: "/images/step_catalog/logos_kubernetes.svg",
            DOCKER_IMAGE_SCANNING: "/images/step_catalog/docker.svg",
            SONAR_REPORT_PUBLISH: "/images/step_catalog/sonar.svg",
            K8S_SERVICE_MANIFEST_CREATOR: "/images/step_catalog/logos_kubernetes.svg",
            IMAGE_CLEANUP: "/images/step_catalog/image 1728.svg",
            OWASP_DEPENDENCY_CHECK: "/images/step_catalog/owasp_logo_flat2_icon 1.svg",
            LICENSE_FINDER: "/images/step_catalog/maxresdefault 1.svg",
            TRIVY_IMAGE_SCANNING: "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            DOCKER_LINTER: "/images/language_icons/docker-logo.svg",
            OWASP_DEPENDENCY_CHECK_STEP: "/images/step_catalog/owasp_logo_flat2_icon 1.svg",
            SONAR_REPORT_PUBLISH: "/images/step_catalog/sonar.svg",
            K8S_SERVICE_MANIFEST_CREATOR: "/images/step_catalog/logos_kubernetes.svg",
            K8S_INGRESS_MANIFEST_CREATOR: "/images/step_catalog/logos_kubernetes.svg",
            K8S_SERVICE_ACCOUNT_MANIFEST_CREATOR: "/images/language_icons/kubernetes-logo.svg",
            IMAGE_LAYER_VALIDATOR_STEP: "/images/step_catalog/imagesizevalidator.svg",
            K8S_DEPLOYMENT_MANIFEST_APPLY: "/images/step_catalog/logos_kubernetes.svg",
            SONAR_SCANNER_STEP: "/images/step_catalog/sonar.svg",
            PUSH_DOCKER_IMAGE: "/images/step_catalog/docker.svg",
            K8S_MANIFEST_ACTIONS: "/images/step_catalog/logos_kubernetes.svg",
            WORKSPACE_PUBLISHER_STEP: "/images/step_catalog/image 1729.svg",
            WORKSPACE_PUBLISHER: "/images/step_catalog/image 1729.svg",
            BITBUCKET_UPDATE_BUILD_STATUS: "/images/step_catalog/bitbukcet.svg",
            JACOCO_REPORT_ANALYSIS: "/images/step_catalog/jacoco.png",
            gchat_notify_step: "/images/step_catalog/images 3.svg",
            K8S_MANIFEST_APPLY: "/images/language_icons/kubernetes-logo.svg",
            JUNIT_REPORT_ANALYSIS: "/images/step_catalog/junit5.svg",
            DOCKER_IMAGE_PROMOTE: "/images/step_catalog/docker.svg",
            TRIVY_FILESYSTEM_SCANNING_STEP: "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            FIND_BUGS_REPORT_ANALYSIS: "/images/language_icons/findbugs.png",
            DOCKER_LINTER_STEP: "/images/language_icons/docker-logo.svg",
            GITLEAKS_CREDS_SCANNING_STEP: "/images/language_icons/git-logo.svg",
            MANIFEST_GENERATE_USING_HELM: "/images/language_icons/helm-logo.svg",
            AWS_RDS_STEP: "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            AWS_IAM_POLICY_CREATOR: "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            AWS_IAM_ROLE_CREATOR: "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            "BP-AWS-IAM-ROLE-POLICY_ASSOCIATION": "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            "AWS-ECS-TF-STEP": "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            "AWS-ECR-STEP": "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            SLACK_NOTIFY: "/images/language_icons/slack.svg",
            K8S_MANIFEST_APPLY_WITH_NAMESPACE: "/images/language_icons/kubernetes-logo.svg",
            CLONING_REPOSITORY: "/images/step_catalog/clonerepositiry.svg",
            BPCTL_APPLY: "/images/step_catalog/Frame 155.svg",
            BUILD_DOCKER_IMAGE: "/images/step_catalog/docker.svg",
            pre_hook_post_hook: "/images/step_catalog/posthookprehook.svg",
            slack: "/images/step_catalog/slack.svg",
            bitBucket: "/images/step_catalog/bitbukcet.svg",
            helm: "/images/step_catalog/Helm.svg",
            jira: "/images/step_catalog/atlassian-jira.png",
            ROLLBACK: "/images/step_catalog/logos_kubernetes.svg",
            "K8's_MANIFEST_APPLY": "/images/step_catalog/logos_kubernetes.svg",
            sonar: "/images/step_catalog/sonar.svg",
            TEAMS_NOTIFY: "/images/step_catalog/teams.png",
            jococo: "/images/step_catalog/jacoco.png",
            junit: "/images/step_catalog/junit5.svg",
            find_bug: "/images/step_catalog/bug-in-code-vector-10832000 2.svg",
            TRIVY_FILESYSTEM_SCANNING: "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            LICENSE_FINDER_STEP_STEP: "/images/step_catalog/maxresdefault 1.svg",
            owapsDepCheckup: "/images/step_catalog/owasp_logo_flat2_icon 1.svg",
            image_clean_up: "/images/step_catalog/image 1728.svg",
            multi_layer: "/images/step_catalog/free-layer-up-down-screen-multiple-interface-ui-13317 1.svg",
            sonar: "/images/step_catalog/sonar.svg",
            workspace: "/images/step_catalog/image 1729.svg",
            gchat_notify: "/images/step_catalog/images 3.svg",
            trivy: "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            BANDIT_SCANNING: "/images/step_catalog/bandit 1.png",
            "AWS-ECS-DEPLOYMENT-STEP": "/images/step_catalog/aws-icon-1024x1024-runl182z 1.png",
            BP_Version_Upgrade: "/images/step_catalog/Frame 155.svg",
            BP_Mysql_Backup: "/images/step_catalog/Frame 155.svg",
            NO_IMAGE: "/images/language_icons/step-icon-default.svg",
            GIT_MANIFEST_CLONE: "/images/language_icons/git-logo.svg",
            Git_Tag_Create: "/images/language_icons/git-logo.svg",
            GITLEAKS_CREDS_SCANNING: "/images/language_icons/git-logo.svg",
            JIRA_INTEGRATION: "/images/language_icons/jira.svg",
            BANDIT_SCANNING_STEP: "/images/step_catalog/bandit 1.png",
            TRIVY_IMAGE_SCANNING_STEP: "/images/step_catalog/1_zEcUVR_Dy2h19HUi4XBJPA 1.svg",
            POST_HOOKS: "/images/language_icons/post-hooks.svg",
            PRE_HOOKS: "/images/language_icons/pre-hooks.svg",
            EMAIL_NOTIFY: "/images/language_icons/email-notification.svg",
        }
    ],
    catalog_images: [
        {
            SLACK_NOTIFY: "/images/language_icons/slack.svg",
            PHP_CODE_ANALYSIS: "/images/language_icons/php.svg",
            DOCKER_IMAGE_PROMOTE: "/images/language_icons/docker-logo.svg",
            NODE_UNIT_TESTING: "/images/language_icons/golang_L.png",
            POST_HOOKS: "/images/language_icons/post-hooks.svg",
            PRE_HOOKS: "/images/language_icons/pre-hooks.svg",
            NODE_CODE_QUALITY: "/images/language_icons/golang_L.png",
            VM_CMD_LS: "/images/language_icons/docker_img.png",
            NODE_SECURITY_SCANNING: "/images/language_icons/clone_repo.png",
            NODE_DEPENDENCY_CHECK: "/images/language_icons/docker-logo.svg",
            JIRA_INTEGRATION: "/images/language_icons/jira.svg",
            EMAIL_NOTIFY: "/images/language_icons/email-notification.svg",
            MANIFEST_GENERATE_USING_HELM: "/images/language_icons/helm-logo.svg",
            ROLLBACK: "/images/language_icons/roll-back.svg",
            K8S_MANIFEST_APPLY_WITH_NAMESPACE: "/images/language_icons/kubernetes-logo.svg",
            FIND_BUGS_REPORT_ANALYSIS: "/images/language_icons/findbugs.png",
            GIT_MANIFEST_CLONE: "/images/language_icons/git-logo.svg",
            NO_IMAGE: "/images/language_icons/step-icon-default.svg",

        }
    ],
    catalog_small_logos:
    {
        SLACK_NOTIFY: { logo: 'dvr', backgroundColor: '#28d19e' },
        NODE_UNIT_TESTING: { logo: 'functions', backgroundColor: '#3a70d1' },
        POST_HOOKS: { logo: 'face', backgroundColor: '#28d19e' },
        DOCKER_IMAGE_PROMOTE: { logo: 'functions', backgroundColor: '#099cec' },
        NODE_CODE_QUALITY: { logo: 'functions', backgroundColor: '#28d19e' },
        VM_CMD_LS: { logo: 'event', backgroundColor: '#099cec' },
        NODE_SECURITY_SCANNING: { logo: 'face', backgroundColor: '#3a70d1' },
        NODE_DEPENDENCY_CHECK: { logo: 'dvr', backgroundColor: '#b0d18a' },
        JIRA_INTEGRATION: { logo: 'note', backgroundColor: '#3a70d1' },
        PRE_HOOKS: { logo: 'dvr', backgroundColor: '#b0d18a' },
        ROLLBACK: { logo: 'dvr', backgroundColor: '#b0d18a' },
        K8S_MANIFEST_APPLY_WITH_NAMESPACE: { logo: 'event', backgroundColor: '#099cec' },
        EMAIL_NOTIFY: { logo: 'dvr', backgroundColor: '#b0d18a' },
        MANIFEST_GENERATE_USING_HELM: { logo: 'functions', backgroundColor: '#b0d18a' },
        FIND_BUGS_REPORT_ANALYSIS: { logo: 'event', backgroundColor: '#099cec' },
        GIT_MANIFEST_CLONE: { logo: 'face', backgroundColor: '#28d19e' }
    }
    ,
    pipelineImg: "/images/menu-line.svg",
    infra_provider: [
        {
            id: 1,
            label: 'AWS',
            value: 1,
            src: '/images/logos/aws.png'
        },
        {
            id: 2,
            label: 'Azure',
            value: 2,
            src: '/images/azure.png'
        },
        {
            id: 3,
            label: 'GCP',
            value: 3,
            src: '/images/logos/gcp.png'
        },
        {
            id: 4,
            label: 'LDC',
            value: 4,
            src: '/images/logos/ldc_icon.png'
        },
        {
            id: 5,
            label: 'Oracle',
            value: 5,
            src: '/images/logos/oracle.png'
        },
        {
            id: 6,
            label: 'Alibaba',
            value: 6,
            src: '/images/logos/alibaba.png'
        },
        {
            id: 7,
            label: 'IBM',
            value: 7,
            src: '/images/logos/ibm.png'
        },
        {
            id: 8,
            label: 'Linode',
            value: 8,
            src: '/images/logos/linode.png'
        }
    ],
    infra_provider_clone: {
        AWS:
        {
            id: 1,
            label: 'AWS',
            value: 1,
            src: '/images/logos/aws.png'
        },
        AZURE:
        {
            id: 2,
            label: 'Azure',
            value: 2,
            src: '/images/logos/azure.png'
        },
        GCP:
        {
            id: 3,
            label: 'GCP',
            value: 3,
            src: '/images/logos/gcp.png'
        },
        BAREMETAL:
        {
            id: 4,
            label: 'Baremetal (LDC)',
            value: 4,
            src: '/images/logos/ldc_icon.png'
        },
        ORACLE:
        {
            id: 5,
            label: 'Oracle',
            value: 5,
            src: '/images/logos/oracle.png'
        },
        ALIBABA:
        {
            id: 6,
            label: 'Alibaba',
            value: 6,
            src: '/images/logos/alibaba.png'
        },
        IBM:
        {
            id: 7,
            label: 'IBM',
            value: 7,
            src: '/images/logos/ibm.png'
        },
        LINODE:
        {
            id: 8,
            label: 'Linode',
            value: 8,
            src: '/images/logos/linode.png'
        }
    },
    language_images_src: {
        PYTHON: "/images/language_icons/python_L.png",
        JAVA: "/images/language_icons/java_L.png",
        NODEJS: "/images/language_icons/node_L.png",
        PHP: "/images/language_icons/php_L.png"
    },
    editMode: [
        {
            id: 1,
            label: "DEV",
            src: "/images/EnvType_icons/dev.png",
        },
    ],
    envs: [
        {
            id: 1,
            label: "DEV",
            src: "/images/EnvType_icons/dev.png",
        },
        {
            id: 2,
            label: "QA",
            src: "/images/EnvType_icons/qa.png"
        },
        {
            id: 3,
            label: "STAGING",
            src: "/images/EnvType_icons/staging.png"
        },
        {
            id: 4,
            label: "UAT",
            src: "/images/EnvType_icons/uat.png"
        },

        {
            id: 5,
            label: "PROD",
            src: "/images/EnvType_icons/prod.png"
        }
    ],
    envs_map: {
        1: {
            id: 1,
            label: "DEV",
            src: "/images/EnvType_icons/dev.png",
            data_class: "ri-code-s-slash-fill"
        },
        2: {
            id: 2,
            label: "QA",
            src: "/images/EnvType_icons/qa.png",
            data_class: "ri-shield-check-line"
        },
        4: {
            id: 4,
            label: "UAT",
            src: "/images/EnvType_icons/uat.png",
            data_class: "ri-database-2-line"
        },
        3: {
            id: 3,
            label: "STAGING",
            src: "/images/EnvType_icons/staging.png",
            data_class: "ri-user-settings-line"
        },
        5: {
            id: 5,
            label: "PROD",
            src: "/images/EnvType_icons/prod.png",
            data_class: "ri-earth-line"
        }
    },
    roles: [
        {
            name: "dev",
            label: "DEV",
            src: "/images/EnvType_icons/dev.png"
        },
        {
            name: "qa",
            label: "QA",
            src: "/images/EnvType_icons/qa.png"
        },
        {
            name: "devops",
            label: "DEVOPS",
            src: "/images/EnvType_icons/prod.png"
        },

    ],
    ad_roles: [
        {
            name: "DEV",
            label: "DEV",
            src: "/images/EnvType_icons/dev.png",
            id: "DEV"
        },
        {
            name: "QA",
            label: "QA",
            src: "/images/EnvType_icons/qa.png",
            id: "QA"
        },
        {
            name: "DEVOPS",
            label: "DEVOPS",
            src: "/images/EnvType_icons/prod.png",
            id: "DEVOPS"
        },
        {
            name: "PROJECT_ADMIN",
            label: "PROJECT_ADMIN",
            src: "/images/EnvType_icons/uat.png",
            id: "PROJECT_ADMIN"
        }
    ],
    colors: {
        1: {
            id: 1,
            color: "#0096db",
        },
        2: {
            id: 2,
            color: "#82A479",
        },
        3: {
            id: 3,
            color: "#A47983",
        },
        4: {
            id: 4,
            color: "#54b4f4",
        },
        5: {
            id: 5,
            color: "#82A479",
        },
        6: {
            id: 6,
            color: "#69e09c",
        },
        7: {
            id: 7,
            color: "#9497CA",
        },
        8: {
            id: 8,
            color: "#208EA2",
        },
        9: {
            id: 9,
            color: "#82A479",
        },
        0: {
            id: 0,
            color: "#6d84a1",
        }
    },
    new_colors: {
        1: {
            id: 1,
            color: "#0086FF",
        },
        2: {
            id: 2,
            color: "#2EBE79",
        },
        3: {
            id: 3,
            color: "#F012CD",
        },
        4: {
            id: 4,
            color: "#124D9B",
        },
        5: {
            id: 5,
            color: "#38E889",
        },
        6: {
            id: 6,
            color: "#FEA111",
        },
        7: {
            id: 7,
            color: "#407BCA",
        },
        8: {
            id: 8,
            color: "#7B61FF",
        },
        9: {
            id: 9,
            color: "#1556D4",
        },
        0: {
            id: 0,
            color: "#FFC700",
        }
    },
    tools: {
        grafana: {
            img: "/images/grafana_icon.png",
        },
        kibana: {
            img: "/images/kibana.png",
        },
        prometheus: {
            img: "/images/prometheus_logo.png",
        },
        alertmanager: {
            img: "/images/alert_mgt_icon.png",
        },
        istio: {
            img: "/images/istio.png",
        },
        sonar: {
            img: "/images/sonar_logo.png",
        },
        metrics: {
            img: "/images/matrics.png"
        }
    },
    oauth_clients: [
        {
            label: "Google",
            id: "GOOGLE"
        }
    ],
    activities: {
        onboard_cluster: {
            header: "Cluster Onboarding is in progress..",
            success_message: "Congrats, Cluster onboarded successfully!",
            fail_message: "Oops, Cluster Onboarding has failed , Please retry.",
            success_page: "ClusterDashboard",
            fail_page: "",
        },
        vm_set_onboarding: {
            header: "VM onboarding is in progress..",
            success_message: "Congrats, VM onboarded successfully!",
            fail_message: "Oops, VM Onboarding has failed , Please retry.",
            success_page: "vm-groups/list",
            fail_page: "",
        },
        dr_cluster_trigger: {
            header: "DR Cluster deployment is in progress..",
            success_message: "Congrats ,DR Cluster deployed successfully!",
            fail_message: "Oops, DR Cluster deployment has failed , Please retry.",
            success_page: "ClusterDashboard",
            fail_page: "",
        },
        add_ingress: {
            header: "Ingress deployment is in progress..",
            success_message: "Congrats , Ingress successfully deployed!",
            fail_message: "Oops, Ingress deployment has failed , Please retry.",
            success_page: "cluster/${cluster_id}/ingress/list",
            fail_page: "cluster/${cluster_id}/ingress/${ingress_id}/edit",
        },
        add_infra_monitoring: {
            header: "Infra Monitoring Deployment in progress..",
            success_message: "Infra Monitoring deployed successfully!",
            fail_message: "Oops , Infra Monitoring deployement has failed , Please Retry",
            success_page: "cluster/${cluster_id}/detail",
            fail_page: ""
        },
        add_log_monitoring: {
            header: "Log Monitoring Deployment in progress..",
            success_message: "Log Monitoring deployed successfully!",
            fail_message: "Oops , Log Monitoring deployement has failed , Please Retry",
            success_page: "cluster/${cluster_id}/detail",
            fail_page: ""
        },
        add_namespace: {
            header: "Namespace Deployment in progress..",
            success_message: "Namespace deployed successfully!",
            fail_message: "Oops , Namespace deployement has failed , Please Retry",
            success_page: "cluster/${cluster_id}/namespace/list",
            fail_page: ""
        },
        add_ci_sonar: {
            header: "Sonar Deployment in progress..",
            success_message: "Sonar deployed successfully!",
            fail_message: "Oops , Sonar deployement has failed , Please Retry",
            success_page: "cluster/${cluster_id}/detail",
            fail_page: ""

        },
        add_vault: {
            header: "Vault Deployment in progress..",
            success_message: "Vault deployed successfully!",
            fail_message: "Oops , Vault deployement has failed , Please Retry",
            success_page: "cluster/${cluster_id}/detail",
            fail_page: ""

        },
        create_infra_aws: {
            header: "Cluster creation in progress..",
            success_message: "Congrats ,Cluster created successfully!",
            fail_message: "Oops, Cluster Creation has failed , Please retry.",
            success_page: "ClusterDashboard",
            fail_page: "/cluster/aws/add",
        },
        add_namespace_configmap: {
            header: "Namespace Config_map deployment  in progress..",
            success_message: "Namespace Config_map deployed successfully!",
            fail_message: "Oops , Namespace Config_map has failed , Please Retry",
            success_page: "application/${application_id}/environment/${env_id}/dashboard",
            fail_page: ""

        },
        add_namespace_secret: {
            header: "Namespace Secret deployment  in progress..",
            success_message: "Namespace Secret deployed successfully!",
            fail_message: "Oops , Namespace Secret has failed , Please Retry",
            success_page: "application/${application_id}/environment/${env_id}/dashboard",
            fail_page: ""

        },
        create_k8s_cluster: {
            header: "Cluster creation in progress..",
            success_message: "Congrats ,Cluster created successfully!",
            fail_message: "Oops, Cluster Creation has failed , Please retry.",
            success_page: "ClusterDashboard",
            fail_page: "/cluster/ldc/add",
        },
        add_istio: {
            header: "Istio creation in progress..",
            success_message: "Congrats ,Istio created successfully!",
            fail_message: "Oops, Gateway Creation has failed , Please retry.",
            success_page: "ClusterDashboard",
            fail_page: "",
        },
        add_gateway: {
            header: "Gateway creation in progress..",
            success_message: "Congrats ,Gateway created successfully!",
            fail_message: "Oops, Gateway Creation has failed , Please retry.",
            success_page: "cluster/${cluster_id}/gateway/list",
            fail_page: "cluster/${cluster_id}/istio/${istio_id}/gateway/add",
        },
        add_istio_addon: {
            header: "Istio Add-ons creation in progress..",
            success_message: "Congrats ,Add-on created successfully!",
            fail_message: "Oops, Gateway Creation has failed , Please retry.",
            success_page: "cluster/${cluster_id}/gateway/list",
            fail_page: "cluster/${cluster_id}/istio/${istio_id}/gateway/add",
        },
        environment_build: {
            header: "Build in progress..",
            success_message: "Congrats ,Build created successfully!",
            fail_message: "Oops, Build has failed , Please retry.",
            success_page: "application/${application_id}/service/listing",
            fail_page: "/application/${application_id}/build_deploy",
        },
        deploy_stateless_app: {
            header: "Deploy in progress..",
            success_message: "Congrats ,Deploy created successfully!",
            fail_message: "Oops, Deploy has failed , Please retry.",
            success_page: "application/${application_id}/service/listing",
            fail_page: "/application/${application_id}/build_deploy",
        },
        deploy_hpa: {
            header: "HPA Deployment in progress..",
            success_message: "Congrats ,HPA deployed created successfully!",
            fail_message: "Oops, Deployment has failed , Please retry.",
            success_page: "application/${application_id}/service/${component_id}/detail",
            fail_page: "application/${application_id}/service/${component_id}/detail",
        },
        disable_hpa: {
            header: "Disabling HPA in progress...",
            success_message: "HPA disabled successfully",
            fail_message: "Oops, Unable to disable HPA, Please retry.",
            success_page: "application/${application_id}/service/${component_id}/detail",
            fail_page: "application/${application_id}/service/${component_id}/detail",
        },
        update_cache_with_system_property: {
            header: "System properties updation in progress...",
            success_message: "System properties updated successfully",
            fail_message: "Oops, Unable to update system properties, Please retry.",
            success_page: "v1-system-settings",
            fail_page: "v1-system-settings",
        },
        rollback_stateless_app: {
            header: "Rollback is in progress..",
            success_message: "Congrats ,Rollback successfull!",
            fail_message: "Oops, Rollback has failed , Please retry.",
            success_page: "application/${application_id}/pipeline/listing",
            fail_page: "",
        },
        approver_state_change: {
            header: "Changing Current State of work items...",
            success_message: "Congrats, All work items state has been changed!",
            fail_message: "Oops unable to change work items state, Please retry.",
            success_page: "finalize/sprint-scope?app_name=${app_name}",
            fail_page: "finalize/sprint-scope?app_name=${app_name}",
        },
        move_work_items_to_sprint: {
            header: "Moving items...",
            success_message: "Congrats, All sprint items has been moved!",
            fail_message: "Oops unable to move sprint items, Please retry.",
            success_page: "finalize/sprint-scope?app_name=${app_name}&path=${path}",
            fail_page: "finalize/sprint-scope?app_name=${app_name}&path=${path}",
        },
        move_selected_bulk_work_items: {
            header: "Moving items...",
            success_message: "Congrats, All sprint items has been moved!",
            fail_message: "Oops unable to move sprint items, Please retry.",
            success_page: "finalize/sprint-scope?app_name=${app_name}&path=${path}",
            fail_page: "finalize/sprint-scope?app_name=${app_name}&path=${path}",
        },
        bulk_move_work_items: {
            header: "Moving work items in bulk...",
            success_message: "Congrats, work items has been moved!",
            fail_message: "Oops unable to move work items, Please retry.",
            success_page: "move-bulk-item?app_name=${app_name}&path=${path}",
            fail_page: "move-bulk-item?app_name=${app_name}&path=${path}",
        }
    },
    yaml_metadata: {
        replicasets: { url: "replicaset_widget", name: 'Replicasets Listing Yaml' },
        deployment: { url: "service_deployment_status_widget_yaml", name: 'Deployment Yaml' },
        pod_listing: { url: "service_deployment_pods_details_widget", name: 'Pods Listing Yaml' }
    },
    signup_partners_list: signup_partners_list ? signup_partners_list : default_signup_partners_list,
    platform: platform ? platform : "PAAS",
    company_name: company_name,
    company_logo: company_logo,
    logo_width: logo_width,
    logo_height: logo_height,
    workspace_url: workspaceURL,
    login_via: login_via,
    flag_for_feature_id: flag_for_feature_id,
    sse_flag: sse_flag,
    release_version: release_version,
    mysql_version: mysql_version,
    redis_version: redis_version,
    public_api_version: public_api_version,
    deploy_api_version: deploy_api_version,
    scheduled_api_version: scheduled_api_version,
    bp_agent_version: bp_agent_version,
    aws_market_place_flag: aws_market_place_flag,
    flag_for_run_params: true,
    third_party_login_fix: thirdPartyLoginFix,
    insight_url: insight_url,
}

