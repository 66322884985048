import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '@material-ui/core/IconButton';
import { getCommonFunctions } from '../../serviceRevamp/add/ci_flow/SourceDetails';
import { Input } from '../../../components/genericComponents/Input';
import ServiceTypeLogo from './component/ServiceTypeLogo';
import { ValidateDataSet } from '../../../util/Validator';
import { Loading } from '../../../views/utils/Loading';
import { Tooltip } from '@material-ui/core';



// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const setDefaultState = (parentState) => {
    let state = { data: {}, error: {}, validations: {}, provided_inputs: [] }

    // if (parentState && parentState.current_target_service) {
    //     state['service_data'] = parentState['type_service_branch_data'][parentState.current_target_service]
    // }
    return state
}

const BranchChangeDialog = ({ onClose, setBranchSaveSuccess, parentState, dialogState, setDialog, ciFetchErrors, setParentState, pipeline_basic_data, branchRequired }) => {
    const [data, setData] = useState(setDefaultState(parentState))

    console.log(data, "sdljknkjsdbhkcbdshkbcvs")
    console.log(parentState, "sdljknkjsdbhkcbdshkbcvs")

    useEffect(() => {
        let currentServiceData = parentState['type_service_branch_data'][parentState.current_target_service]
        let formData = {

        }
        let validations = {}
        let given_inputs = []


        console.log(currentServiceData, "zjkbchjbchjdsb")
        let editedData = parentState['change_service_branch_final_data'][parentState.current_target_service]
        // editedData={}
        console.log(editedData, "sdkbbhchjbdshc")
        currentServiceData && Object.keys(currentServiceData).forEach(subEnv => {

            formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`] = []
            formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.no_cache`] = false

            if (editedData) {
                if (editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`]) {
                    formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`] =
                        editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`]
                    given_inputs.push(`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`)

                }
                if (editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.version`]) {
                    formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.version`] =
                        editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.version`]
                }
                if (editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`]) {
                    console.log('settng_data_for_edit_data', `${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`)
                    formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`] =
                        editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`]
                }
                if (editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.no_cache`]) {
                    formData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.no_cache`] =
                        editedData[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.no_cache`]
                }
            }
            if (currentServiceData[subEnv].default_brnach) {
                validations[`${currentServiceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`]
                    = []
            }
        })

        setData(prevState => ({
            ...prevState,
            data: formData,
            validations: validations,
            provided_inputs: [...given_inputs]

        }))

    }, [parentState.type_service_branch_data])

    console.log(data.data, "bdmfbdhbfhfbhjfb")

    useEffect(() => {
        setData(prevState => ({
            ...prevState,
            error: { ...ciFetchErrors }
        }))

    }, [ciFetchErrors])
    ///env_build_tags_list_url
    const serviceData = parentState['type_service_branch_data'][parentState.current_target_service]
    useEffect(() => {
        if (branchRequired) {
            getIsCustomBranch();
        }
    }, [data.data])
    const handleOnSaveClick = () => {

        let result = ValidateDataSet(data.data, data.validations);
        if (result.valid) {
            var all_keys = Object.keys(data.data)
            if (all_keys && all_keys.length > 0) {
                all_keys.forEach(item => {
                    if (data.data[item] === "") {
                        delete data.data[item]
                    }
                })
            }
            const existingComponentError = { ...parentState.componentListError }
            existingComponentError[parentState.current_target_service] = false
            // checking if all services has branches 
            let allserviceBranchSatisfied = true
            Object.keys(existingComponentError)?.forEach(serviceName => {
                if (existingComponentError[serviceName]) {
                    allserviceBranchSatisfied = false
                }
            })
            console.log(data);
            if (!allserviceBranchSatisfied) {

                setParentState(prevState => ({
                    ...prevState,
                    change_service_branch_final_data: {
                        ...prevState.change_service_branch_final_data,
                        [parentState.current_target_service]: { ...data.data }
                    },
                    edited_daya_by_services: {
                        ...prevState.edited_daya_by_services,
                        [parentState.current_target_service]: { ...data.data }
                    },
                    componentListError: { ...existingComponentError }

                }))

            }
            else {
                let error = { ...parentState.error }
                error.components = null
                setParentState(prevState => ({
                    ...prevState,
                    change_service_branch_final_data: {
                        ...prevState.change_service_branch_final_data,
                        [parentState.current_target_service]: { ...data.data }
                    },
                    edited_daya_by_services: {
                        ...prevState.edited_daya_by_services,
                        [parentState.current_target_service]: { ...data.data }
                    },
                    componentListError: { ...existingComponentError },
                    error: { ...error }

                }))
            }
            onClose()
            setBranchSaveSuccess(true)
        }
        else {
            setData(prevState => ({
                ...prevState,
                error: { ...prevState.error, ...result.error }
            }))
        }
    }


    const commonFunctions = getCommonFunctions(data, setData, {});


    const onKeyValueChangeHandler = (k, v) => {
        setData(prevState => ({
            ...prevState,
            data: { ...prevState.data, [k]: v },
            error: { ...prevState.error, [k]: null }
        }));
    }
    function onBranchChangeHandler(event, service_data, input_name) {
        const key = event.target.name;
        let commit_sha_key = getCommitShaKey(key)
        const value = event.target.value;
        console.log(key, value, data, "ftddgdcgcvhgsd")
        if (value) {
            if (!data.provided_inputs.includes(key))
                setData((new_state) => ({
                    ...new_state,
                    provided_inputs: [...new_state.provided_inputs, key],
                    data: {
                        ...new_state.data,
                        [commit_sha_key]: getBranchCommitSha(value, service_data)
                    }
                }))
        } else {
            var updated_arr = []
            data.provided_inputs.forEach((item) => {
                if (item != key) {
                    updated_arr.push(item)
                }
            })
            setData((new_state) => ({
                ...new_state,
                provided_inputs: [...updated_arr],
                data: {
                    ...new_state.data,
                    [commit_sha_key]: getBranchCommitSha(value, service_data)
                }
            }))
        }

        setData((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [key]: value,
            },
            selected_service_data: service_data,
            selected_input_name: input_name
        }));

    }
    function getIsCustomBranch() {

        console.log(data.data, serviceData, "skfbdkshfckdsbcjhbdsc")

        var is_any_feild_custom = false;

        var all_subEnvs_branches = []

        var all_keys = []

        serviceData && Object.keys(serviceData).forEach((subEnv) => {
            console.log(subEnv, "sdfbsdhbchjbdsc")
            var obj = {}
            obj[`${serviceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`] = serviceData[subEnv].branches
            console.log(obj, "sdfbsdhbchjbdsc")
            all_subEnvs_branches.push(obj);
            all_keys.push(`${serviceData[subEnv]['master']['name']}.${parentState.current_target_service}.build.branch`)
        })

        all_keys.forEach((key) => {

            var branches_list;

            all_subEnvs_branches.forEach((item) => {
                if (item[key]) {
                    branches_list = item[key]
                }
            })

            console.log(branches_list, "dsbfjbsdhjbsdcbds")

            if (branches_list && !branches_list.includes(data.data[key])) {
                is_any_feild_custom = true;
            }

            setData((new_state) => ({
                ...new_state,
                custom_branch_value: is_any_feild_custom,
            }))
        })

        console.log(is_any_feild_custom, "skfbdkshfckdsbcjhbdsc")

        setData((new_state) => ({
            ...new_state,
            custom_branch_value: is_any_feild_custom,
        }))

    }

    function isAllValuesProvided() {
        if (serviceData && Object.keys(serviceData).length == data.provided_inputs.length) {
            return true;
        } else {
            return false;
        }
    }

    function getBranchType(service_data, input_name) {
        let branch_list = []

        if (service_data.branches) {
            service_data.branches.forEach(branch => {
                branch_list.push(branch)
            })
        }

        var is_custom;

        if (data.data[input_name]) {
            for (let i = 0; i < branch_list.length; i++) {
                if (branch_list[i] == data.data[input_name]) {
                    is_custom = false;
                    break;
                } else {
                    is_custom = true;
                }
            }
        }

        return is_custom;
    }


    function handleInputTypeChange(input_name) {
        delete data.data[input_name]

        if (data.provided_inputs.includes(input_name)) {
            var updated_arr = []

            data.provided_inputs.forEach((item) => {
                if (item != input_name) {
                    updated_arr.push(item)
                }
            })

            setData((new_state) => ({
                ...new_state,
                provided_inputs: updated_arr
            }))
        }
    }

    console.log("jdnfdjn", data);

    function getBranchCommitSha(value, list) {
        console.log(value, list)
        if (list && list.branch_with_commit_sha) {
            for (let obj of list.branch_with_commit_sha) {
                console.log(obj)
                for (let [key, val] of Object.entries(obj)) {
                    console.log(key, val)
                    if (key === value) {
                        return val;
                    }
                }
            }
        } else {
            return null;
        }
    }

    function getCommitShaKey(input) {

        // Split the input string by dots
        let parts = input.split('.');

        // Replace the last segment with the new segment
        parts[parts.length - 1] = "commit_sha";

        // Join the parts back together
        return parts.join('.');
    }

    function onChangeHandler(e, data) {
        let name = e.target.name
        let commit_sha_name = getCommitShaKey(name)
        let value = e.target.value
        console.log(name, value, data, "sdbvcgsjdvcgsdvcds")

        setData((new_state) => ({
            ...new_state,
            data: {
                ...new_state.data,
                [name]: value,
                [commit_sha_name]: getBranchCommitSha(value, data),
            }
        }))
    }

    return (
        <Dialog
            className="dialog-wrapper-pipeline integrations-dialog action_card_dialog_width"
            open={true}
            maxWidth={"md"}
            // TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <div >
                <div className="align-center space-between pl-20 pr-20" style={{ backgroundColor: '#124d9b', color: '#fff', height: '62px', display: 'flex' }}>
                    <label className='font-20'>{`Change Branch for ${parentState.current_target_service ? parentState.current_target_service : 'Service'}`}</label>
                    <IconButton onClick={onClose}>
                        <CloseIcon className='font-24' style={{ color: "#fff" }} />
                    </IconButton>
                </div>
                <div className="pl-20 pr-20 align-center" style={
                    pipeline_basic_data.manual_deploy_tag_select ? { height: '40px', backgroundColor: '#eeeeee', display: 'grid', gridTemplateColumns: '10% 12% 10% 21% 21% 21%', gap: '5px', }
                        :
                        { height: '40px', backgroundColor: '#eeeeee', display: 'grid', gridTemplateColumns: '15% 20% 20% 40%', gap: '5px', }
                }>
                    <span style={{ color: '#000000' }} className='font-14'>Env Type</span>
                    <span style={{ color: '#000000' }} className='font-14'>Selected Env</span>
                    <span style={{ color: '#000000' }} className='font-14'>No Cache Use</span>
                    <span style={{ color: '#000000' }} className='font-14'>Change Branch</span>
                    {
                        pipeline_basic_data.manual_deploy_tag_select ?
                            <>
                                <span style={{ color: '#000000' }} className='font-14'> Deploy Tag</span>
                                <span style={{ color: '#000000' }} className='font-14'> Select Deployment </span>
                            </>
                            : null
                    }

                </div>

                {

                    <>
                        <div style={{ height: '350px', overflow: 'auto' }}>
                            {

                                serviceData ?
                                    Object.keys(serviceData).map((subEnvData, index) => {
                                        console.log(serviceData[subEnvData])
                                        // var complete_operation = false
                                        let isBranchloading = true
                                        let isTextboxSelected = false
                                        let isTagsloading = true
                                        let isDeploymentsLoading = true
                                        let isTagsTextboxSelected = false
                                        let fieldName = `${serviceData[subEnvData]['master']['name']}.${parentState.current_target_service}.build.branch`
                                        let fieldNameTag = `${serviceData[subEnvData]['master']['name']}.${parentState.current_target_service}.deploy.version`
                                        let fieldDeploymentName = `${serviceData[subEnvData]['master']['name']}.${parentState.current_target_service}.deploy.deployment_name`
                                        let fieldNameNoCache = `${serviceData[subEnvData]['master']['name']}.${parentState.current_target_service}.build.no_cache`
                                        console.log(fieldNameNoCache, "bdmfbdhbfhfbhjfb")
                                        if (serviceData[subEnvData] && serviceData[subEnvData].branches) {
                                            isBranchloading = false
                                            if (!serviceData[subEnvData].branches.includes(data.data[fieldName])) {
                                                isTextboxSelected = false
                                            }
                                        }
                                        else if (data.error[fieldName]) {
                                            isBranchloading = false
                                        }
                                        if (serviceData[subEnvData] && serviceData[subEnvData].tags) {
                                            isTagsloading = false
                                            if (!serviceData[subEnvData].tags.includes(data.data[fieldName])) {
                                                isTagsTextboxSelected = true
                                            }

                                            // Assuming this is an asynchronous operation, you can use a Promise
                                            // const includesOperation = new Promise((resolve, reject) => {
                                            //     // Assuming serviceData[subEnvData].tags is an array
                                            //     if (!serviceData[subEnvData].tags.includes(data.data[fieldName])) {
                                            //         // If the condition is true, resolve the promise
                                            //         isTagsTextboxSelected = true
                                            //         resolve();
                                            //     } else {
                                            //         reject();
                                            //     }
                                            // });

                                            // // Using the promise then method to update anunay after the includes operation is completed
                                            // includesOperation.then(() => {
                                            //     complete_operation = true;
                                            // }).catch(() => {
                                            //     complete_operation = true;
                                            // })

                                        } else if (data.error[fieldNameTag] || !serviceData[subEnvData].tags) {

                                            isTagsloading = false
                                        }
                                        if (serviceData[subEnvData] && serviceData[subEnvData].deployment_name_list) {
                                            isDeploymentsLoading = false
                                        } else if (data.error[isDeploymentsLoading]) {

                                            isDeploymentsLoading = false
                                        }
                                        return (
                                            <div className="pl-20 pr-20 align-center" style={pipeline_basic_data.manual_deploy_tag_select ? {
                                                display: 'grid',
                                                borderBottom: '1px solid #ededed',
                                                gridTemplateColumns: '10% 12% 10% 21% 21% 21%',
                                                gap: '5px',
                                                paddingTop: '15px',
                                                paddingBottom: '12px',
                                                minHeight: '75px',
                                            }
                                                :
                                                {
                                                    display: 'grid',
                                                    borderBottom: '1px solid #ededed',
                                                    gridTemplateColumns: '15% 20% 20% 40%',
                                                    gap: '5px',
                                                    paddingTop: '15px',
                                                    paddingBottom: '12px',
                                                    minHeight: '75px',
                                                }}>
                                                <ServiceTypeLogo name={serviceData[subEnvData]['master']['name']} />
                                                <label style={{ color: '#484848' }} className='font-14'>{subEnvData}</label>
                                                {/* {
                                                    complete_operation ?

                                                        <div className='input-component-mb-0'>
                                                            <Input
                                                                type="only-switch"
                                                                label=""
                                                                name={fieldNameNoCache}
                                                                data={data.data}
                                                                error={data.error}
                                                                onChangeHandler={commonFunctions.toggleState} />
                                                        </div>
                                                        :
                                                        <div><Loading varient="light" /></div>
                                                } */}
                                                <div className='input-component-mb-0'>
                                                    <Input
                                                        type="only-switch"
                                                        label=""
                                                        name={fieldNameNoCache}
                                                        data={data.data}
                                                        error={data.error}
                                                        onChangeHandler={commonFunctions.toggleState} />
                                                </div>
                                                <div style={{ marginBottom: `${isBranchloading ? '' : '15px'}`, height: '100%' }}>

                                                    {
                                                        isBranchloading ?
                                                            <div><Loading varient="light" /></div>
                                                            :
                                                            <>
                                                                <div style={{ position: "relative" }}>

                                                                    <Input
                                                                        type='dual-input'
                                                                        // isFirstTimeDropdown={!isTextboxSelected}
                                                                        isFirstTimeDropdown={branchRequired ? data.data[fieldName] ? !getBranchType(serviceData[subEnvData], fieldName) : true : !isTextboxSelected}
                                                                        label='  '
                                                                        placeholder="master"
                                                                        name={fieldName}
                                                                        list={serviceData[subEnvData].branches && serviceData[subEnvData].branches.map(branch => {
                                                                            return { id: branch, label: branch }
                                                                        })}
                                                                        // list={getBranchesList(serviceData[subEnvData].branches && serviceData[subEnvData].branches)}
                                                                        data={data.data}
                                                                        error={data.error}
                                                                        onChangeHandler={branchRequired ? (e) => onBranchChangeHandler(e, serviceData[subEnvData], fieldName) : /* commonFunctions.onChangeHandler */ (e) => onChangeHandler(e, serviceData[subEnvData])}
                                                                        onInputTypeChange={branchRequired ? () => handleInputTypeChange(fieldName) : () => { }}
                                                                    />
                                                                </div>
                                                                {
                                                                    branchRequired &&
                                                                    data.data[fieldName] && getBranchType(serviceData[subEnvData], fieldName) &&
                                                                    <p style={{ color: "#E95454", fontSize: "12px", position: "absolute" }}>The custom branch provided does not exist in the loaded list of branches</p>
                                                                }
                                                                {data.error[fieldName] &&
                                                                    <Tooltip title={data.error[fieldName]}>
                                                                        <p className='font-11 text-ellipsis-150 ' style={{ color: '#cc0033' }}>{data.error[fieldName]}</p>
                                                                    </Tooltip>
                                                                }
                                                            </>
                                                    }

                                                </div>
                                                {
                                                    pipeline_basic_data.manual_deploy_tag_select &&
                                                    <div style={{ marginBottom: `${isTagsloading ? '' : '15px'}`, height: '100%' }}>
                                                        {
                                                            isTagsloading ?
                                                                <div><Loading varient="light" /></div>
                                                                :
                                                                <>
                                                                    <Input
                                                                        type='dual-input'
                                                                        isFirstTimeDropdown={isTagsTextboxSelected}
                                                                        label='  '
                                                                        placeholder="Enter Custom Tag"
                                                                        name={fieldNameTag}
                                                                        list={serviceData[subEnvData].tags && serviceData[subEnvData].tags.map(tag => {
                                                                            return { id: tag, label: tag }
                                                                        })}
                                                                        data={data.data}
                                                                        error={data.error}
                                                                        onChangeHandler={commonFunctions.onChangeHandler}
                                                                    />
                                                                    {data.error[fieldNameTag] && <p className='font-11 ' style={{ color: '#cc0033' }}>{data.error[fieldNameTag]}</p>}
                                                                </>
                                                        }

                                                    </div>
                                                }
                                                <div className='multiple-select-deployment-dropdown'>
                                                    {
                                                        isDeploymentsLoading ?
                                                            <div><Loading varient="light" /></div>
                                                            :
                                                            <Input
                                                                type="select-with-checkbox"
                                                                name={fieldDeploymentName}
                                                                label=""
                                                                mandatorySign
                                                                data={data.data}
                                                                error={data.error}
                                                                placeholder="Enter keyword to search tag"
                                                                list={serviceData[subEnvData].deployment_name_list && serviceData[subEnvData].deployment_name_list?.length > 0 ?
                                                                    serviceData[subEnvData].deployment_name_list : []}
                                                                onChangeHandler={onKeyValueChangeHandler}
                                                            />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                    // : <p className='font-12 color-red'>Data Not Available</p>
                                    : <p className='font-12 color-red'></p>

                            }
                        </div>
                        {
                            branchRequired ?
                                isAllValuesProvided() ?
                                    data.custom_branch_value ?
                                        <div className="pd-10 pl-20 pr-10 border-top d-flex align-center space-between">
                                            <p style={{ fontSize: "14px", color: "#787878" }}>Note : <span style={{ color: "#2F2F2F" }}>The custom branch selected does not exist in the loaded list of branches. <span style={{ color: "#E95454" }}>Do you want to continue.</span></span></p>
                                            <button style={{ width: '100px', backgroundColor: "#E95454" }} className="btn btn-red" onClick={handleOnSaveClick}> Continue</button>
                                        </div>
                                        :
                                        <div className="pd-10 pl-20 pr-10 border-top d-flex align-center space-between">
                                            <button style={{ width: '100px' }} className="btn btn-outline-grey" onClick={() => { onClose() }}>Close</button>
                                            <button style={{ width: '100px' }} className="btn btn-primary" onClick={handleOnSaveClick}> Save</button>
                                        </div>
                                    :
                                    <div className="pd-10 pl-20 pr-10 border-top d-flex align-center space-between">
                                        <button style={{ width: '100px' }} className="btn btn-outline-grey" onClick={() => { onClose() }}>Close</button>
                                        <Tooltip title="You have not provided branch values">
                                            <button className="btn  btn-disable cursor-not-allowed">Save</button>
                                        </Tooltip>
                                        {/* <button style={{ width: '100px' }} className="btn btn-primary" onClick={handleOnSaveClick}> Save</button> */}
                                    </div>
                                :
                                <div className="pd-10 pl-20 pr-10 border-top d-flex align-center space-between">
                                    <button style={{ width: '100px' }} className="btn btn-outline-grey" onClick={() => { onClose() }}>Close</button>
                                    <button style={{ width: '100px' }} className="btn btn-primary" onClick={handleOnSaveClick}> Save</button>
                                </div>
                        }

                    </>
                }

            </div>
        </Dialog>
    )
}

BranchChangeDialog.propTypes = {
    ...PropTypes.objectOf(PropTypes.any),
}


export default BranchChangeDialog